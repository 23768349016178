import React from 'react'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { gql } from 'apollo-boost';
import { Mutation, Query } from '@apollo/client/react/components';
import ReportSubcategory from './reportsubcategory';



class ReportCategory extends React.Component {
	constructor(props) {
		super(props);
		let defaultcolour;
		if (this.props.colour=="") {
			defaultcolour="#C9DAF8";
		} else {
			defaultcolour=this.props.colour;
		}

		this.state = {subcategories: this.props.subcategories,
						modalShow: false,
						lang: "en",
						maxGrade: this.props.categoryMax,
						categoryEn: this.props.categoryEn,
						categoryJp: this.props.categoryJp,
						colour: defaultcolour,
			};
		this.categoryId = this.props.categoryId;
		this.editMaxGrade = this.editMaxGrade.bind(this);
		this.maxGradeDict = {};
	}
	
	changeLang(event) {
		const target = event.target;
		const value = target.value;
	}
	
	editMaxGrade(subcatId,gradeLvl) {
		this.maxGradeDict[subcatId] = gradeLvl;
		const result = Object.entries(this.maxGradeDict).reduce((a, b) => a[1] > b[1] ? a : b)[1];
		if (result<10) {
			this.setState({maxGrade: result+1});
		} else {
			this.setState({maxGrade: 10});
		}
		
	}

	render() {
		const langEn = ((this.state.lang!="en") ? " inactive-lang" : "");
		const langJp = ((this.state.lang!="jp") ? " inactive-lang" : "");
		
		const gradeLevels = [];
		for (let i = 1; i < this.state.maxGrade+1; i++) {
			gradeLevels.push(<th key={"GH"+i} className="report-grade">{i}</th>);
		}
		
		return (
			<Row className="report-category">
				<div className="report-category-heading" style={{ backgroundColor: this.state.colour}}>
					<h2>
						<div className={"report-category-heading-input" + langEn}>{this.state.categoryEn}</div>
						<div className={"report-category-heading-input" + langJp}>{this.state.categoryJp}</div>
					</h2>
				</div>
			
				
				<Table striped hover responsive size="sm">
					<thead>
						<tr>
							<th className="">
								
								<ButtonGroup className="report-language-toggle" toggle>
									<ToggleButton
										key="en"
										type="radio"
										variant="primary"
										name="radio"
										value={"en"}
										checked={this.state.lang === "en"}
										onChange={(e) => this.setState({lang: e.currentTarget.value})}
									  >EN</ToggleButton>
									<ToggleButton
										key="jp"
										type="radio"
										variant="primary"
										name="radio"
										value={"jp"}
										checked={this.state.lang === "jp"}
										onChange={(e) => this.setState({lang: e.currentTarget.value})}
									  >JP</ToggleButton>
									
								</ButtonGroup>
							</th>
							<th className="report-subcategory-level"></th>
							
							{gradeLevels}
						</tr>
					</thead>
					<tbody className={"report-catmax-"+this.state.maxGrade}>
						{this.state.subcategories.map((subcat) =>
							<ReportSubcategory key={"SC"+subcat.subcategory_id} subcategoryId={subcat.subcategory_id}  maxGradeFunc={this.editMaxGrade} subcategoryMaxGrade={subcat.subcategory_max}
							subcategoryEn={subcat.subcategory_en} subcategoryJp={subcat.subcategory_jp} lang={this.state.lang} removeSCFunc={this.removeSubcategory} categoryId={this.categoryId}
							subcategoryUnit={subcat.subcategory_unit} grades={subcat.grades}/>
						)}
				  </tbody>
				  
				</Table>
			</Row>
		);		
		
		
	}
}




export default ReportCategory;
