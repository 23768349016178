import React from 'react'
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {LinkContainer} from 'react-router-bootstrap'
import { gql } from 'apollo-boost';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Mutation, Query } from '@apollo/client/react/components';



const GET_STUDENTS = gql`
  query students {
    students {
		student_id
		japanese_name
		english_name
	}
  }
`;
const NEW_STUDENT = gql`
	mutation createStudent($name_en: String, $name_jp: String) {
		createStudent(name_en: $name_en, name_jp: $name_jp){
			student_id
			english_name
			japanese_name
		}	
		
	}
`;
const SEARCH_STUDENTS = gql`
  query searchStudents($text: String, $limit: Int) {
    searchStudents(text: $text, limit: $limit) {
		student_id
		japanese_name
		english_name
	}
  }
`;

class StudentList extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {students:[],newStudentShow:false,newStudentEnglishName:"",newStudentJapaneseName:"",searchQuery:"",createdNotice:false,createdStudent:{english_name:"",japanese_name:""}};
		this.showNewStudentCard = this.showNewStudentCard.bind(this);
		this.hideNewStudentCard = this.hideNewStudentCard.bind(this);
		this.handleChangeEnglishName = this.handleChangeEnglishName.bind(this);
		this.handleChangeJapaneseName = this.handleChangeJapaneseName.bind(this);
		this.createNewStudent = this.createNewStudent.bind(this);
		this.search = this.search.bind(this);
		this.createSearch = this.createSearch.bind(this);
	}
	
	
	async componentDidMount() {
		this.mounted = true;
		while(this.mounted && !this.props.data.hasOwnProperty("students"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			
			this.setState({students: this.props.data.students});
			document.title = "Fuji - Students";
		}
	}
	
	showNewStudentCard() {
		this.setState({newStudentShow: true});
	}
	hideNewStudentCard() {
		this.setState({newStudentShow: false});
		this.research();
		this.setState({createdNotice: false});
	}
	handleChangeEnglishName(event){
		const target = event.target;
		const value = target.value;
		this.setState({newStudentEnglishName: value});
		this.createSearch(value,this.state.newStudentJapaneseName);
		this.setState({createdNotice: false});
	}
	handleChangeJapaneseName(event){
		const target = event.target;
		const value = target.value;
		this.setState({newStudentJapaneseName: value});
		this.createSearch(this.state.newStudentEnglishName,value);
		this.setState({createdNotice: false});
	}
	async createNewStudent(){
		const { data } = await this.onNewStudentMutate(this.state.newStudentEnglishName,this.state.newStudentJapaneseName);
		
		if (data.createStudent) {
			this.setState({newStudentEnglishName: ""});
			this.setState({newStudentJapaneseName: ""});
			this.setState({createdStudent: data.createStudent});
			this.setState({createdNotice: true});
		}
	}
	
	async search(event) {
		
		const target = event.target;
		const value = target.value;
		this.setState({searchQuery: value});
		
		const {data} = await this.props.client.query({
		  query: SEARCH_STUDENTS,
		  variables: { text:value, limit:20 },
		  fetchPolicy: "network-only",
		});
		if (data) {
			this.setState({students: data.searchStudents});
		}
		
	  }
	  
	async createSearch(english_name,japanese_name) {
		
		const {data} = await this.props.client.query({
		  query: SEARCH_STUDENTS,
		  variables: { text:english_name+" "+japanese_name, limit:20 },
		});
		if (data) {
			this.setState({students: data.searchStudents});
		}
		
	}  
	
	async research() {
		

		const {data} = await this.props.client.query({
		  query: SEARCH_STUDENTS,
		  variables: { text:this.state.searchQuery, limit:20 },
		});
		if (data) {
			this.setState({students: data.searchStudents});
		}
		
	  }
	
  render() {
	  let newStudentCard;
	  if (this.state.newStudentShow==true) {
		  newStudentCard = <Card style={{ width: '18rem' }} className="studentlist-new-card">
			<div className="d-flex justify-content-end"><Button variant="secondary" onClick={this.hideNewStudentCard}>Cancel</Button></div>
				<Mutation mutation={NEW_STUDENT}>
					{(newStudentMutate) => {
						this.onNewStudentMutate = (name_en, name_jp) => newStudentMutate( {variables: { name_en, name_jp } } )
						return (
							null
						)
					}
				 }
				</Mutation>	
				<InputGroup className="mb-3">
					<InputGroup.Prepend>
					  <InputGroup.Text id="basic-addon1">English Name</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control
					  placeholder="English Name"
					  aria-label="English Name"
					  aria-describedby="basic-addon1"
					onChange={this.handleChangeEnglishName}
					value={this.state.newStudentEnglishName}/>
				  </InputGroup>
				  
				  <InputGroup className="mb-3">
					<InputGroup.Prepend>
					  <InputGroup.Text id="basic-addon2">Japanese Name</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control
					  placeholder="Japanese Name"
					  aria-label="Japanese Name"
					  aria-describedby="basic-addon2"
					onChange={this.handleChangeJapaneseName}
					value={this.state.newStudentJapaneseName}/>
				  </InputGroup>
				  <Button variant="success" onClick={this.createNewStudent} >Create</Button>
					  
			</Card>
			
	  } else {
			newStudentCard=<div className="d-flex align-items-center flex-column"><div><Button variant="success" size="sm" className="class-link-button" onClick={this.showNewStudentCard}>New Student</Button></div>
				<div><InputGroup className="mb-3">
					<InputGroup.Prepend>
					  <InputGroup.Text id="basic-addon3">Search</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control
					  placeholder="Student name"
					  aria-label="Search"
					  aria-describedby="basic-addon3"
					onChange={this.search}
					value={this.state.searchQuery}/>
				  </InputGroup>
				  </div>
			</div>;
		
	  }
	  let createdMessage;
	  if (this.state.createdNotice){
		  createdMessage = <div className="success-create-notice">{this.state.createdStudent.english_name} successfully created</div>
	  }
	  return (
		<div className="active">
		
			<Container fluid="true">
				<div className="classlist-title">
					<h1>Students</h1>
				</div>
				<Row>
				<Col className="d-flex justify-content-center">
					{newStudentCard}
					
				</Col>	
				</Row>
				{createdMessage}
				<Row className="studentlist">
					<Col className="d-flex justify-content-center">
					<ListGroup>
						{this.state.students.map((st) =>

								<LinkContainer to={"/student/"+st.student_id} key={"S"+st.student_id}>
								<ListGroup.Item action className="studentlist-list-item">
									{st.english_name} {st.japanese_name}
								</ListGroup.Item>
								</LinkContainer>
							)}
					  
					</ListGroup>
					</Col>
				</Row>

				
			
			</Container>
		  
		</div>
	  );
  }
}

export default withApollo(graphql(GET_STUDENTS, {
  options: { fetchPolicy: 'network-only' },
})(StudentList));