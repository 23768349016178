import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import {LinkContainer} from 'react-router-bootstrap'
import Header from './header';
import { gql } from 'apollo-boost';
import { graphql } from '@apollo/client/react/hoc';
import Spinner from 'react-bootstrap/Spinner';


const GET_ARCHIVED_CLASSES = gql`
	query getArchivedClasses {
		getArchivedClasses {
			term_year
			year_list {
				term_number
				term_id
				term_list {
					class_name
					class_id
					class_teacher
					class_unit
				}
			}
		}
	}
`;


class Archive extends React.Component {
	constructor(props) {
		super(props);
		this.title = "Fuji - Archives";
		this.state = {classes:[],newClassShow:false,newClassName:"",newTeacher:"", loading:true};
		this.getClassColour = this.getClassColour.bind(this);
	}
	
	
	async componentDidMount() {
		this.mounted = true;
		while(this.mounted && !this.props.data.hasOwnProperty("getArchivedClasses"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			this.setState({loading: false});
			this.setState({classes: this.props.data.getArchivedClasses});
			document.title = "Fuji - Archive";
		}
	}
	
	getClassColour(unit){
		if (unit>0 && unit<11) return "class-unit-blue";
		else if (unit>10 && unit<21) return "class-unit-green";
		else if (unit>20 && unit<31) return "class-unit-yellow";
		else if (unit>30) return "class-unit-red";
		else return "class-unit-purple";
	}
	
  render() {
	
	  return (
		<div className="active">
			<Container fluid="true">
				<div className="classlist-title">
					<h1>Class Archives</h1>
				</div>
				{!!this.state.loading && <div className="d-flex justify-content-center w-100"><Spinner animation="border" variant="primary" /></div>}
				<Accordion>
				
				{this.state.classes.map((year) =>
						
					  <><div className="archive-year" key={"Y"+year.term_year}>{year.term_year}</div>
					  {year.year_list.map((term) =>
							   <Card key={"T"+term.term_id}>
								<Accordion.Toggle as={Card.Header} eventKey={"C"+term.term_id} className="archive-term">
									Term {term.term_number}
								</Accordion.Toggle>
								<Accordion.Collapse eventKey={"C"+term.term_id}>
								  <Card.Body>
								<div className="classlist w-100">
								<Col className="d-flex justify-content-center">
								<ListGroup horizontal>
									{term.term_list.map((course) =>
											<LinkContainer to={"/class/"+course.class_id} key={"LC"+course.class_id}>
												<ListGroup.Item action className={"classlist-list-item "+this.getClassColour(course.class_unit)}>
													<div className="classlist-class-name">{course.class_name}</div>
													<div className="classlist-class-bottom">
														<div className="classlist-teacher">{course.class_teacher}</div>
														<div className="classlist-class-unit">{!!course.class_unit && 'U'}{course.class_unit}</div>
													</div>
												</ListGroup.Item>
											</LinkContainer>
										)}
								  
								</ListGroup>
								</Col>
							</div>
							</Card.Body>
						</Accordion.Collapse>
					  </Card>

					  )}
					</>
					  
				)}
				
				  
				</Accordion>
			
			</Container>
		  
		</div>
	  );
  }
}

export default graphql(GET_ARCHIVED_CLASSES, {
  options: { fetchPolicy: 'network-only' },
})(Archive);