import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { gql } from 'apollo-boost';
import { Mutation } from '@apollo/client/react/components';

import TextareaAutosize from 'react-textarea-autosize';


const EDIT_GRADE_EN = gql`
	mutation editReportGradeEn($gid: Int, $text: String) {
	  editReportGradeEn(gid: $gid, text: $text)
	}
`;

const EDIT_GRADE_JP = gql`
	mutation editReportGradeJp($gid: Int, $text: String) {
	  editReportGradeJp(gid: $gid, text: $text)
	}
`;


class ReportGrade extends React.Component {
	constructor(props) {
		super(props);
		this.state = {gradeEn: this.props.gradeEn, gradeJp: this.props.gradeJp, gradeLvl: this.props.gradeLvl, modalShow: false};
		this.gradeId = this.props.gradeId;
		this.handleGradeEnChange = this.handleGradeEnChange.bind(this);  
		this.handleGradeJpChange = this.handleGradeJpChange.bind(this);  
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.typingTimerEn = null;
		this.typingTimerJp = null;
	}
	
	async componentWillUnmount() {
		if (this.typingTimerEn) {
			this.onEditGradeEnMutate( parseInt(this.gradeId), this.state.gradeEn );
			clearTimeout(this.typingTimerEn);
		}
		if (this.typingTimerJp) {
			this.onEditGradeJpMutate( parseInt(this.gradeId), this.state.gradeJp )
			clearTimeout(this.typingTimerJp);
		}
	}
	
	
	handleGradeEnChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({gradeEn: value});
		clearTimeout(this.typingTimerEn);
		this.typingTimerEn = setTimeout(() => {
			this.onEditGradeEnMutate( parseInt(this.gradeId), value );
			this.typingTimerEn = null;
		}, 1000);
	}
	
	handleGradeJpChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({gradeJp: value});
		clearTimeout(this.typingTimerJp);
		this.typingTimerJp = setTimeout(() => {
			this.onEditGradeJpMutate( parseInt(this.gradeId), value )
			this.typingTimerJp = null;
		}, 1000);
	}
	
	handleClose() {
		this.setState({modalShow: false});
	}
	handleShow(){
		this.setState({modalShow: true});
		
	}

	render() {
		const langEn = ((this.props.lang!="en") ? "inactive-lang" : "");
		const langJp = ((this.props.lang!="jp") ? "inactive-lang" : "");
		
		let delButton;
		if (this.props.maxFunc()==this.state.gradeLvl && this.state.gradeLvl>2) {
			delButton = <><Button className="report-delete-grade" variant="danger" onClick={this.handleShow}>Delete Grade</Button>
			<Modal show={this.state.modalShow} onHide={this.handleClose} animation={false} centered>
				<Modal.Header>
					<Modal.Title>Confirm delete</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to delete this grade?</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
				<Button variant="danger" onClick={this.props.removeGFunc.bind(this,this.gradeId)}>Delete</Button>
				</Modal.Footer>
			</Modal></>;
		} else {
			delButton = "";
		}
		
		return (
			<td className="report-grade">
				
				<Mutation mutation={EDIT_GRADE_EN}>
					{(editGradeEnMutate) => {
						this.onEditGradeEnMutate = (gid, text) => editGradeEnMutate( {variables: { gid, text } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Mutation mutation={EDIT_GRADE_JP}>
					{(editGradeJpMutate) => {
						this.onEditGradeJpMutate = (gid, text) => editGradeJpMutate( {variables: { gid, text } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<TextareaAutosize className={langEn} value={this.state.gradeEn} onChange={this.handleGradeEnChange} />
				<TextareaAutosize className={langJp} value={this.state.gradeJp} onChange={this.handleGradeJpChange} />
				{delButton}
				
					  
			</td>
		);		
		
		
	}
}



export default ReportGrade;
