import React from 'react'
import Container from 'react-bootstrap/Container';
import { gql } from 'apollo-boost';
import { graphql } from '@apollo/client/react/hoc';
import Jumbotron from 'react-bootstrap/Jumbotron';
import {LinkContainer} from 'react-router-bootstrap';
import countdown from 'countdown';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';

const GET_HOME = gql`
  query getHomeData {
    getHomeData {
		motd
		due_title
		due_date
	}
  }
`;

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {motd:null, due_title:null, due_date:null, timer: null, days:null, hours:null, minutes:null, seconds:null, loading:true}; 

		this.timerId = null;
	}
	
	async componentWillUnmount() {
		window.clearInterval(this.timerId);
	}
	
	async componentDidMount() {
		this.mounted = true;
		
		while(this.mounted && !this.props.data.hasOwnProperty("getHomeData"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			const dd = new Date(this.props.data.getHomeData.due_date)
			this.setState({motd: this.props.data.getHomeData.motd,
							due_title: this.props.data.getHomeData.due_title,
							due_date: dd});
			
			if (dd<new Date()) {
				this.setState({days: 0,
									hours: 0,
									minutes: 0,
									seconds: 0});
			} else {
			const thisd = this;
			this.timerId =  countdown(dd,
				function(ts) {
					thisd.setState({days: ts.days,
									hours: ts.hours,
									minutes: ts.minutes,
									seconds: ts.seconds});
					if (ts.days + ts.hours + ts.minutes + ts.seconds == 0) {
						window.clearInterval(thisd.timerId);
					}
				},
				countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS);
				
			}
			this.setState({loading: false});
		}
	}

	
	render() {
		let motd;
		if (this.state.motd) {
			motd = <div className="home-motd"><h4>{this.state.motd}</h4></div>
		}
		
		let cdblock;
		if (this.state.due_title && this.state.due_date) {
			cdblock = <div className="home-countdown">
				<h1>{this.state.due_title}</h1>
				<div className="home-countdown-timer">
					<div className="home-countdown-block">
						<p className="home-countdown-digit">{this.state.days}</p>
						<p className="home-countdown-unit">DAYS</p>
					</div>
					<div className="home-countdown-block">
						<p className="home-countdown-digit">{this.state.hours}</p>
						<p className="home-countdown-unit">HOURS</p>
					</div>
					<div className="home-countdown-block">
						<p className="home-countdown-digit">{this.state.minutes}</p>
						<p className="home-countdown-unit">MINUTES</p>
					</div>
					<div className="home-countdown-block">
						<p className="home-countdown-digit">{this.state.seconds}</p>
						<p className="home-countdown-unit">SECONDS</p>
					</div>
				</div>
				<div>
					Time until {this.state.due_date.toString()}
				</div>
			</div>;
		}
		
		return (
		
			<>
			<Container>
			{!!this.state.loading && <Row className="d-flex justify-content-center"><Spinner animation="border" variant="primary" /></Row>}
			{motd}
			{cdblock}
			
			
			<LinkContainer to="/active">
				<Jumbotron className="home-jumbo">
				  <h1>Active Classes</h1>
				  <p>
					Grade students in the ongoing classes for the current term.
				  </p>
				  <p>
					
				  </p>
				</Jumbotron>
			</LinkContainer>
			
			<LinkContainer to="/archive">
				<Jumbotron className="home-jumbo">
				  <h1>Archives</h1>
				  <p>
					View the classes for previous terms.
				  </p>
				  <p>
					
				  </p>
				</Jumbotron>
			</LinkContainer>
			
			<LinkContainer to="/student">
				<Jumbotron className="home-jumbo">
				  <h1>Students</h1>
				  <p>
					View student records and add new students to the system.
				  </p>
				  <p>
					
				  </p>
				</Jumbotron>
			</LinkContainer>
			
			<LinkContainer to="/report">
				<Jumbotron className="home-jumbo">
				  <h1>Report</h1>
				  <p>
					View the report comments linked to grades.
				  </p>
				  <p>
					
				  </p>
				</Jumbotron>
			</LinkContainer>
			
		  </Container>
		  </>

		);
	}
}	

export default graphql(GET_HOME, {
  options: { fetchPolicy: 'network-only' },
})(Home);