import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { gql } from 'apollo-boost';
import { withApollo } from '@apollo/client/react/hoc';

const SEARCH_STUDENTS = gql`
  query searchStudents($text: String, $limit: Int) {
    searchStudents(text: $text, limit: $limit) {
		student_id
		japanese_name
		english_name
		class_id
	}
  }
`;


class StudentSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state={searchQuery:"", searchResult:[]}
		this.search = this.search.bind(this);
	}
	
	
	async search(event) {
		this.props.hideErrorFunc();
		const target = event.target;
		const value = target.value;
		this.setState({searchQuery: value});
		if (value!='') {
			const {data} = await this.props.client.query({
			  query: SEARCH_STUDENTS,
			  variables: { text:value, limit:7 },
			  fetchPolicy: "network-only",
			});
			this.setState({searchResult: data.searchStudents});
		} else {
			this.setState({searchResult: []});
		}
	  }
	
	
	render() {
	  return (
		<div className="StudentSearch">
			<InputGroup className="mb-3">
			<InputGroup.Prepend>
			  <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
			</InputGroup.Prepend>
			<Form.Control
			  placeholder="Student name"
			  aria-label="Search"
			  aria-describedby="basic-addon1"
			onChange={this.search}
			value={this.state.searchQuery}/>
		  </InputGroup>

		  <ListGroup variant="flush">
			{this.state.searchResult.map((result) =>
								<ListGroup.Item action key={result.student_id} onClick={this.props.addStudentFunc.bind(this,result.student_id)} disabled={(result.class_id)}>{result.english_name} {result.japanese_name}</ListGroup.Item>
								)}
			
		  </ListGroup>
		  
		</div>
	  );
	  }
	}



export default withApollo(StudentSearch);