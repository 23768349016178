import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StudentList from './studentlist';
import StudentRecord from './studentrecord';
import Header from './header';





class Student extends React.Component {
  render() {
  return (
    <div className="Student">
		
			<Switch>
				<Route exact path={this.props.match.path} component={StudentList} />
				<Route path={'/student/:id'} render={(props) => <StudentRecord {...props} /> } />
			</Switch>
		
      
    </div>
  );
  }
}

export default Student;