import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { gql } from 'apollo-boost';
import { Mutation, Query } from '@apollo/client/react/components';
import ReportGrade from './reportgrade';



class ReportSubcategory extends React.Component {
	constructor(props) {
		super(props);
		let subMax = this.props.subcategoryMaxGrade;
		if (subMax<2) {
			subMax = 2;
		}
		this.state = {grades: this.props.grades,
						subcategoryEn: this.props.subcategoryEn,
						subcategoryJp: this.props.subcategoryJp,
						subcategoryUnit: this.props.subcategoryUnit,
						subcategoryMax: subMax,
						modalShow: false};
		this.categoryId = this.props.categoryId;
		this.subcategoryId = this.props.subcategoryId;
		this.getMax = this.getMax.bind(this);
	}
	

	getMax(){
		return this.state.grades.length;
	}
	

	render() {
		const langEn = ((this.props.lang!="en") ? "inactive-lang" : "");
		const langJp = ((this.props.lang!="jp") ? "inactive-lang" : "");
		
		
		return (
		
			<tr>
				<td className="report-subcategory-heading">
					
					<div className={langEn}>{this.state.subcategoryEn}</div>
					<div className={langJp}>{this.state.subcategoryJp}</div>
					
				</td>
				
				<td className="report-subcategory-level">
					<div>{this.state.subcategoryUnit}</div>
				
				</td>
				
				{this.state.grades.map((grade,ind) =>
					<ReportGrade key={"G"+grade.grade_id} gradeId={grade.grade_id} gradeEn={grade.grade_en} gradeJp={grade.grade_jp}  lang={this.props.lang}
						gradeLvl={grade.grade_level} removeGFunc={this.removeGrade} maxFunc={this.getMax}/>
				)}
				
			</tr>

			
		);		
		
		
	}
}





export default ReportSubcategory;
