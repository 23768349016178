import React from 'react';


class P404 extends React.Component {
	constructor(props) {
		super(props);

	}
	


	render() {

			return (
				<div className="p404">
					<h1>404</h1>
					<p>Page not found</p>
					
				</div>
				
			);		
		}
		
	}



export default P404;