import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { gql } from 'apollo-boost';
import { Mutation } from '@apollo/client/react/components';
import TextareaAutosize from 'react-textarea-autosize';
import Modal from 'react-bootstrap/Modal';
import translate from "translate";
import CourseGradeInput from './coursegrade';
import {LinkContainer} from 'react-router-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';

const EDIT_COMMENT_EN = gql`
	mutation editStudentCommentEn($stid: Int , $tid: Int, $cen: String) {
	  editStudentCommentEn(stid: $stid, tid: $tid, cen: $cen)
	}
`;
const EDIT_COMMENT_JP = gql`
	mutation editStudentCommentJp($stid: Int , $tid: Int, $cjp: String) {
	  editStudentCommentJp(stid: $stid, tid: $tid, cjp: $cjp)
	}
`;

/**
 * Row for a student in the class table
 * Contains names, grades and comment
 */
class CourseRow extends React.Component {
	constructor(props) {
		super(props);
		this.gradesDict = {}
		this.props.grades.forEach(grade => {
			this.gradesDict[grade.subcategory_id] = grade.grade_level;
			});
		let prevGradesDict = {}
		this.props.prevGrades.forEach(grade => {
			prevGradesDict[grade.subcategory_id] = grade.grade_level;
		});	

		let prevPrevGradesDict = {}
		this.props.prevPrevGrades.forEach(grade => {
			prevPrevGradesDict[grade.subcategory_id] = grade.grade_level;
			
		});
		let prevGradesBorder = {}
		this.props.subcategories.forEach(subcategory => {
			prevGradesBorder[subcategory.subcategory_id] = {}
			prevGradesBorder[subcategory.subcategory_id].border = 0;
			prevGradesBorder[subcategory.subcategory_id].changeText = "";
			prevGradesBorder[subcategory.subcategory_id].changeColor = "blue";
		});

		this.props.prevGrades.forEach(grade => {
			if (grade.grade_level != "" && grade.grade_level != "X" && prevPrevGradesDict[grade.subcategory_id] !== undefined && prevPrevGradesDict[grade.subcategory_id] != "" && prevPrevGradesDict[grade.subcategory_id] != "X") {
				prevGradesBorder[grade.subcategory_id] = {}
				let change = parseInt(grade.grade_level) - parseInt(prevPrevGradesDict[grade.subcategory_id]);
				if (change>0) {
					prevGradesBorder[grade.subcategory_id].border = "2px solid blue";
					if (change>1) {
						prevGradesBorder[grade.subcategory_id].changeText = "+" + change;
					} else prevGradesBorder[grade.subcategory_id].changeText = "";
					prevGradesBorder[grade.subcategory_id].changeColor = "blue";
				} else if (change<0) {
					prevGradesBorder[grade.subcategory_id].border = "2px solid red";
					if (change<-1) {
						prevGradesBorder[grade.subcategory_id].changeText = change;
					} else prevGradesBorder[grade.subcategory_id].changeText = "";
					prevGradesBorder[grade.subcategory_id].changeColor = "red";
				} else {
					prevGradesBorder[grade.subcategory_id].border = 0;
					prevGradesBorder[grade.subcategory_id].changeText = "";
					prevGradesBorder[grade.subcategory_id].changeColor = "blue";
				}
				
			} else {
				prevGradesBorder[grade.subcategory_id] = {}
				prevGradesBorder[grade.subcategory_id].border = 0;
				prevGradesBorder[grade.subcategory_id].changeText = "";
				prevGradesBorder[grade.subcategory_id].changeColor = "blue";
			}
			
		});	

		let commentEnState = "";
		if (this.props.commentEn) {
			commentEnState = this.props.commentEn;
		}
		let commentJpState = "";
		if (this.props.commentJp) {
			commentJpState = this.props.commentJp;
		}
		let prevCommentEnState = "";
		if (this.props.prevCommentEn) {
			prevCommentEnState = this.props.prevCommentEn;
		}
		let prevCommentJpState = "";
		if (this.props.prevCommentJp) {
			prevCommentJpState = this.props.prevCommentJp;
		}
		this.state = {grades:this.gradesDict, prevGrades:prevGradesDict, prevGradesBorder:prevGradesBorder, commentEn:commentEnState, commentJp:commentJpState, 
			prevCommentEn:prevCommentEnState, prevCommentJp:prevCommentJpState, modalShow:false, active:false, removeModalShow:false, gAllSC:-1, gAllLvl:"", popoverShow:false,
			charCount:commentEnState.length, modalFixClass:"", focusCat:-1, focusRow:false};
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);		
		this.handleRemoveClose = this.handleRemoveClose.bind(this);
		this.handleRemoveShow = this.handleRemoveShow.bind(this);	
		
		this.handleCommentEnChange = this.handleCommentEnChange.bind(this);  
		this.handleCommentJpChange = this.handleCommentJpChange.bind(this);  

		this.typingTimerEn = null;
		this.typingTimerJp = null;
		
		this.activatePrevious = this.activatePrevious.bind(this);  
		this.deactivatePrevious = this.deactivatePrevious.bind(this);

		this.translateToJp = this.translateToJp.bind(this);

		this.showDetails = this.showDetails.bind(this);
		this.hideDetails = this.hideDetails.bind(this);
		
		this.editGradeDict = this.editGradeDict.bind(this);
		this.maxChars = "700";

		this.engRef = React.createRef();
		this.jpRef = React.createRef();
		this.focusEng = this.focusEng.bind(this);
		this.focusJp = this.focusJp.bind(this);
	}
	handleClose() {
		this.setState({modalShow: false});
	}
	handleShow(){
		this.setState({modalShow: true});
	}
	handleRemoveClose() {
		this.setState({removeModalShow: false});
	}
	handleRemoveShow(){
		this.setState({removeModalShow: true});
	}	
	
	async componentWillUnmount() {
		if (this.typingTimerEn) {
			this.onEditCommentEnMutate( parseInt(this.props.studentId), parseInt(this.props.termId), this.state.commentEn );
			clearTimeout(this.typingTimerEn);
		}
		if (this.typingTimerJp) {
			this.onEditCommentJpMutate( parseInt(this.props.studentId), parseInt(this.props.termId), this.state.commentJp )
			clearTimeout(this.typingTimerJp);
		}
	}
	
	//If data is changed from outside this component, update accordingly
	componentDidUpdate(prevProps) {
		if (this.props.currentActiveStid != prevProps.currentActiveStid) {
			if (this.props.currentActiveStid != this.props.studentId) {
				this.setState({active: false});
			}
		}
		if (this.props.gAllSC != prevProps.gAllSC || this.props.gAllLvl != prevProps.gAllLvl) {
			this.setState({gAllSC: this.props.gAllSC, gAllLvl:this.props.gAllLvl});
		}
		if (this.props.focusRow != prevProps.focusRow || this.props.focusCat != prevProps.focusCat) {
			if (this.props.focusRow == this.props.focusRowId) {
				this.setState({focusCat: this.props.focusCat, focusRow: !this.state.focusRow});
			}
		}
	}
	//Autosubmit comments one second after typing stops
	handleCommentEnChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({commentEn: value, charCount:value.length});
		clearTimeout(this.typingTimerEn);
		this.typingTimerEn = setTimeout(() => {
			this.onEditCommentEnMutate( parseInt(this.props.studentId), parseInt(this.props.termId), value );
			this.typingTimerEn = null;
		}, 1000);
	}
	
	handleCommentJpChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({commentJp: value});
		clearTimeout(this.typingTimerJp);
		this.typingTimerJp = setTimeout(() => {
			this.onEditCommentJpMutate( parseInt(this.props.studentId), parseInt(this.props.termId), value )
			this.typingTimerJp = null;
		}, 1000);
	}	
	
	activatePrevious(){

		this.props.activeFunc(this.props.studentId);
		this.setState({active: true});

	}
	deactivatePrevious(){
		this.setState({active: false});
	}
	
	showDetails(){
		this.setState({popoverShow: true});
	}
	hideDetails(){
		this.setState({popoverShow: false});
	}
	
	editGradeDict(scid, grade){
		this.gradesDict[scid] = grade;
		this.setState({grades: this.gradesDict});
	}
	
	//Submit English comment for translation to Japanese
	async translateToJp() {
			
		this.setState({loading: true});
		
		let tempComment = this.state.commentEn;

		this.props.translations.forEach((translation) => {
			const regex = new RegExp('\\b' + translation.original + '\\b', "g");
			tempComment = tempComment.replaceAll(regex, "<Q"+translation.original.replace(/ /g,'')+"Q>");	
		});
		
		let jp = await translate(tempComment, "ja");
		
		this.props.translations.forEach((translation) => {
			jp = jp.replaceAll("<Q"+translation.original.replace(/ /g,'')+"Q>", translation.replacement);
		});
		
		this.setState({commentJp: jp});
		this.onEditCommentJpMutate( parseInt(this.props.studentId), parseInt(this.props.termId), jp )
		this.setState({loading: false});
	}

	focusEng(){
		this.engRef.current.scrollIntoView();
	}
	
	focusJp(){
		this.jpRef.current.scrollIntoView();
	}
	
	render() {
		//Row that is only displayed when student is selected. Shows previous term grades
		let previousTerm;
		if (this.state.active==true) {
			previousTerm = <tr className="class-student-row class-row-previous">
				<td className="class-student-button">
					<LinkContainer to={"/student/"+this.props.studentId}>
						<Button>Records</Button>
					</LinkContainer>
				</td>
				<td className="course-names" onClick={this.deactivatePrevious}></td>
				{this.props.subcategories.map((subcat) =>
				<td className="class-grade-previous" key={"PG"+subcat.subcategory_id}>
					<div className="h-100 d-flex justify-content-center align-items-center" style={{border:this.state.prevGradesBorder[subcat.subcategory_id].border}}>{this.state.prevGrades[subcat.subcategory_id]}</div>
					<div className="class-input-change" style={{color:this.state.prevGradesBorder[subcat.subcategory_id].changeColor}}>{this.state.prevGradesBorder[subcat.subcategory_id].changeText}</div>
					</td>
					)}
				{this.props.activeClass && <td className="class-student-button"><Button variant="danger" onClick={this.handleRemoveShow}>Remove {this.props.englishName}</Button></td>}
				{!this.props.activeClass && <td></td>}
				<Modal show={this.state.removeModalShow} onHide={this.handleRemoveClose} animation={false} centered>
					<Modal.Header closeButton>
						<Modal.Title>Confirm remove</Modal.Title>
					</Modal.Header>
					<Modal.Body>Are you sure you want to remove {this.props.englishName}?</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={this.handleRemoveClose}>Cancel</Button>
					<Button variant="danger" onClick={this.props.removeStudentFunc.bind(this,this.props.studentId)}>Remove</Button>
					</Modal.Footer>
				</Modal>
			</tr>
			
		}
		let invalidGenerate = "";
		if (this.state.commentEn.trim()=="" || this.state.commentJp.trim()=="") {
			invalidGenerate = " class-invalid-grade";
		}

		let translateButton = <Button variant="primary" onClick={this.translateToJp}>Translate</Button>;
		if (this.state.loading == true) {
			translateButton = <Spinner animation="border" variant="primary" />;
		}
		//Preview data that is placed into the report according to the grades
		let previewBody = (<>{this.props.subcategories.map((subcat) =>
			<div key={"PR"+subcat.subcategory_id}>{!!(this.props.reportComments[subcat.subcategory_id].grades[parseInt(this.state.grades[subcat.subcategory_id])]) && 
				<div className="class-report-preview-group"  style={{backgroundColor: subcat.colour}}>
				<div className="class-report-preview-cat">{subcat.subcategory_en}</div>
				<div className="d-flex">
					<div className="class-report-preview-grade">{this.props.reportComments[subcat.subcategory_id].grades[parseInt(this.state.grades[subcat.subcategory_id])]}</div>
					<div className="class-report-preview-grade">{this.props.tooltips[subcat.subcategory_id][parseInt(this.state.grades[subcat.subcategory_id])]}</div>
				</div>
			</div>}</div>
		  )}</>);

		//Show comments on hover
		let commentTooltip;
		if (this.state.commentEn != "" ||this.state.commentJp !="") {
			commentTooltip = <Tooltip className="class-student-comment-tooltip" placement="left">
							{this.state.commentEn}
							<div className="underline"></div>
							{this.state.commentJp}
					</Tooltip>
		}				

		return (
			<>
			{previousTerm}
			
			<tr className="class-student-row" onClick={this.activatePrevious}>
				<td className="course-names sticky-name"><span>{this.props.englishName}</span></td>
				<td className="course-names no-padding"><div className="course-names-inner">{this.props.japaneseName}</div>
					<div><p className="grade-comment-info" onClick={this.showDetails}>i</p></div>
					
					<Modal show={this.state.popoverShow} onHide={this.hideDetails} animation={false} centered>
						<Modal.Header className="class-report-preview-header" closeButton>
						<Modal.Title>{this.props.englishName}</Modal.Title>
						</Modal.Header>
						<Modal.Body className="class-report-preview-body">
							{previewBody}
						</Modal.Body>
					</Modal>
				</td>

				{this.props.subcategories.map((subcat,index) =>
				<CourseGradeInput key={"SG"+subcat.subcategory_id+this.props.studentId} maxGrade={subcat.subcategory_max} colour={subcat.colour} editAvgFunc={this.props.editAvgFunc}
					gradeLvl={this.state.grades[subcat.subcategory_id]} studentId={this.props.studentId} termId={this.props.termId} subcategoryId={subcat.subcategory_id} gAllSC={this.state.gAllSC} 
					gAllLvl={this.state.gAllLvl}
					activeClass={this.props.activeClass} excludeAvg={this.props.excludeAvg} tooltips={this.props.tooltips[subcat.subcategory_id]} editGradeFunc={this.editGradeDict}
					activeStudent={this.activatePrevious} prevGrade={this.state.prevGrades[subcat.subcategory_id]}
					focusCat={this.state.focusCat} focusCatId={index} focusRowId={this.props.focusRowId} focusRow={this.state.focusRow} focusDown={this.props.focusDown} focusUp={this.props.focusUp} />
					)}
				<td className={"class-student-comment"+invalidGenerate} onClick={this.handleShow}>
					<div className="class-student-comment-overflow"><span>{this.state.commentEn}</span></div>
						{commentTooltip}
				</td>
				<Mutation mutation={EDIT_COMMENT_EN}>
					{(editCommentEnMutate) => {
						this.onEditCommentEnMutate = (stid, tid, cen) => editCommentEnMutate( {variables: { stid, tid, cen } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Mutation mutation={EDIT_COMMENT_JP}>
					{(editCommentJpMutate) => {
						this.onEditCommentJpMutate = (stid, tid, cjp) => editCommentJpMutate( {variables: { stid, tid, cjp } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Modal show={this.state.modalShow} onHide={this.handleClose} animation={false} centered backdropClassName={this.state.modalFixClass}>
					<Modal.Header closeButton>
					<Modal.Title>Comments for {this.props.englishName}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{!!this.props.activeClass && <><Form.Label className="class-student-comment-label">English<span>{this.state.charCount}/{this.maxChars}</span></Form.Label>
							<TextareaAutosize value={this.state.commentEn} onChange={this.handleCommentEnChange} className="class-student-comment-modal" maxLength={this.maxChars}
								onFocus={this.focusEng} ref={this.engRef} autoComplete="off"/>
							<div className="d-flex justify-content-center">{translateButton}</div>
							<Form.Label>Japanese</Form.Label>
							<TextareaAutosize value={this.state.commentJp} onChange={this.handleCommentJpChange} className="class-student-comment-modal"
								onFocus={this.focusJp} ref={this.jpRef}  autoComplete="off"/></>}
						{!this.props.activeClass && <><p>{this.state.commentEn}</p><p>{this.state.commentJp}</p></>}
					</Modal.Body>
					<Modal.Body className="class-student-prev-comment"><div>Previous term comments:</div>
						<p>{this.state.prevCommentEn}</p>
						<p>{this.state.prevCommentJp}</p>
					</Modal.Body>
				</Modal>
			</tr>
			</>
		);
	}
}


export default CourseRow;