import React from 'react'
import Form from 'react-bootstrap/Form';
import { gql } from 'apollo-boost';
import { Mutation } from '@apollo/client/react/components';
import Tooltip from 'react-bootstrap/Tooltip';

const EDIT_GRADE = gql`
	mutation editStudentGrade($stid: Int ,$scid: Int, $tid: Int, $glvl: String) {
	  editStudentGrade(stid: $stid, scid: $scid, tid: $tid, glvl: $glvl)
	}
`;

/**
 * Input field for grades within a row of the class table
 */
class CourseGradeInput extends React.Component {
	constructor(props) {
		super(props);
		let gradeLvl = "";
		let tooltip = null;
		if (this.props.gradeLvl) {
			gradeLvl = this.props.gradeLvl;
			if (gradeLvl != "") {
				tooltip = this.props.tooltips[gradeLvl];
			}
		}
		//Show change in grade from previous term with a coloured border, with the change value if greater than abs(1)
		let border = "0";
		let changeText = "";
		let changeColor = "blue";
		if (this.props.prevGrade != "" && this.props.prevGrade != "X" && gradeLvl != "" && gradeLvl != "X") {
			let change = parseInt(gradeLvl) - parseInt(this.props.prevGrade);
		
			if (change>0) {
				border = "2px solid blue";
				if (change>1) {
					changeText = "+"+change;
				}
			}
			if (change<0) {
				border = "2px solid red";
				if (change<-1) {
					changeText = change;
					changeColor = "red";
				}
			}
		}
		this.state = {gradeLvl:gradeLvl, tooltipShow:false, tooltip:tooltip, border:border, changeText:changeText, changeColor:changeColor, showOptionsClass:"options-hide"};
		this.optionClick = this.optionClick.bind(this);
		this.showOptions = this.showOptions.bind(this);
		this.hideOptions = this.hideOptions.bind(this);
		this.focusStudent = this.focusStudent.bind(this);
		this.posRef = React.createRef();
		this.handleGradeChange = this.handleGradeChange.bind(this);
		this.onKeyPressed = this.onKeyPressed.bind(this);
		
	}
	//If data is changed outside of this class, update accordingly	
	componentDidUpdate(prevProps) {
		if (this.props.gAllSC != prevProps.gAllSC || this.props.gAllLvl != prevProps.gAllLvl) {
			if (this.props.subcategoryId == this.props.gAllSC) {
				this.setState({gradeLvl:this.props.gAllLvl});
				this.props.editAvgFunc(this.props.subcategoryId, this.props.studentId,this.props.gAllLvl);
				this.setState({tooltip: this.props.tooltips[this.props.gAllLvl]});
			}
		}
		if (this.props.focusRow != prevProps.focusRow) {
			if (this.props.focusCat == this.props.focusCatId) {
				this.posRef.current.focus();
			}
		}
	}
	
	optionClick(v, event) {
		event.preventDefault();
		let value = v.toString();
		this.handleGradeChange(value);
	}
	
	
	handleGradeChange(value) {
		this.setState({gradeLvl: value});
		//Submit to database
		this.onEditGradeMutate( parseInt(this.props.studentId), parseInt(this.props.subcategoryId), parseInt(this.props.termId), value );
		//Update class average
		if (this.props.excludeAvg==false) {
			this.props.editAvgFunc(this.props.subcategoryId, this.props.studentId,value);
		} else {
			this.props.editAvgFunc(this.props.subcategoryId, this.props.studentId,"X");
		}
		//Update tooltip to match new grade
		if (value != "") {
			this.setState({tooltip: this.props.tooltips[value]});
		} else {
			this.setState({tooltip: ""});
		}
		//Set the change from previous term border
		let border = "0";
		let changeText = "";
		let changeColor = "blue";
		if (this.props.prevGrade != "" && this.props.prevGrade != "X" && value != "" && value != "X") {
			let change = parseInt(value) - parseInt(this.props.prevGrade);
		
			if (change>0) {
				border = "2px solid blue";
				if (change>1) {
					changeText = "+"+change;
				}
			}
			if (change<0) {
				border = "2px solid red";
				if (change<-1) {
					changeText = change;
					changeColor = "red";
				}
			}
		}
		this.setState({border:border, changeText:changeText, changeColor:changeColor, showOptionsClass:"options-hide"});
		this.props.editGradeFunc(this.props.subcategoryId, value);
	}
	
	//Keybinds
	onKeyPressed(event){
		let keyPressed = event.key;
		//Unset grade on delete or backspace
		if (keyPressed=="Delete" || keyPressed=="Backspace") {
			this.handleGradeChange("");
		//Space to move down a row, shift+space to move up a row
		} else if (keyPressed==" ") {
			event.preventDefault();
			if (event.shiftKey) {
				this.props.focusUp(this.props.focusRowId,this.props.focusCatId);
			} else {
				this.props.focusDown(this.props.focusRowId,this.props.focusCatId);
			}
		//Number keys to set to that number, with 0 representing 10
		} else if (!isNaN(keyPressed) && keyPressed<(this.props.maxGrade+1) && keyPressed>-1) {
			if (keyPressed=="0") {
				if (this.props.maxGrade>9) this.handleGradeChange("10");
			} else this.handleGradeChange(keyPressed);
		//X to set to X (unassessed)
		} else if (keyPressed=="x") {
			this.handleGradeChange("X");
		}
	}
	//Display previous term details for this student
	focusStudent() {
		this.props.activeStudent();
	}
	//Custom select box
	showOptions() {
		if (this.state.showOptionsClass=="options-hide") {
			let rect = this.posRef.current.getBoundingClientRect();
			if (rect.left<window.innerWidth-100) {
				this.setState({showOptionsClass:"options-show"});
			} else this.setState({showOptionsClass:"options-show-left"});
			
		} else this.setState({showOptionsClass:"options-hide"});
	}
	
	hideOptions() {
		this.setState({showOptionsClass:"options-hide"});
	}
	
	render() {
		//Fill custom select box with options
		let options = [];
		let divOptions = [];
		divOptions.push(<div key={"DO0"} onMouseDown={this.optionClick.bind(this,"")}></div>);
		for (let i = 1; i < this.props.maxGrade+1; i++) {
			options.push(<option key={"GI"+i} value={i}>{i}</option>);
			divOptions.push(<div key={"DO"+i} onMouseDown={this.optionClick.bind(this,i)}>{i}</div>);
		}
		divOptions.push(<div key={"DOX"} onMouseDown={this.optionClick.bind(this,"X")}>X</div>);
		
		//Show when no grade is input when attempting to generate report
		let invalidGenerate = "";
		if (this.state.gradeLvl=="") {
			invalidGenerate = " class-invalid-grade";
		}
		
		let tooltip;
		if (this.state.tooltip) {
			tooltip = <Tooltip className="class-grade-tooltip" placement="left">
						{this.state.tooltip}
					</Tooltip>;
		}
		
		return (
			 <td className={"class-input-grade"+invalidGenerate} style={{backgroundColor:this.props.colour}}>
				<Mutation mutation={EDIT_GRADE}>
					{(editGradeMutate) => {
						this.onEditGradeMutate = (stid, scid, tid, glvl) => editGradeMutate( {variables: { stid, scid, tid, glvl } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				
				  
				{this.props.activeClass && <>
				<div className="class-input-grade-select" tabIndex="0"
						style={{border:this.state.border}}
						onKeyDown={this.onKeyPressed}
						onMouseDown={this.showOptions}
						onBlur={this.hideOptions}
						onFocus={this.focusStudent}
						ref={this.posRef}>{this.state.gradeLvl}</div>
				<div className={"class-input-grade-options "+this.state.showOptionsClass}>
					{divOptions}
				</div>
				<div className="class-input-change" style={{color:this.state.changeColor}}>{this.state.changeText}</div></>}
				{!this.props.activeClass && <><div className="h-100 d-flex justify-content-center align-items-center" style={{border:this.state.border}}>{this.state.gradeLvl}</div>
					<div className="class-input-change" style={{color:this.state.changeColor}}>{this.state.changeText}</div></>}
				{tooltip}
				
				
			  </td>
				
		);
	}
}


export default CourseGradeInput;