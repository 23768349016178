import React from 'react'
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { gql } from 'apollo-boost';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Accordion from 'react-bootstrap/Accordion';
import TextareaAutosize from 'react-textarea-autosize';
import Card from 'react-bootstrap/Card';

const GET_TERM = gql`
  query getCurrentTerm {
    getCurrentTerm {
		term_id
		term_number
		term_year
	}
	getHomeData {
		motd
		due_title
		due_date
	}
	getTranslations{
		original
		replacement
	}
	reportCategories {
		category_id
		category_en
		colour
		subcategories {
			subcategory_id
			subcategory_en
			grades {
				grade_id
				grade_level
				grade_en
			}
		}
	}
	getTooltips {
		grade_level
		subcategory_id
		tooltip
	} 
  }
  
`;

const TRANSITION_TERM = gql`
	mutation transitionTerm {
	  transitionTerm
	}
`;


const GET_HOME = gql`
  query getHomeData {
    getHomeData {
		motd
		due_title
		due_date
	}
  }
`;


const SET_MOTD = gql`
	mutation setMOTD($motd: String) {
	  setMOTD(motd: $motd)
	}
`;

const SET_DUE = gql`
	mutation setDue($due_title: String, $due_date: String) {
	  setDue(due_title: $due_title, due_date: $due_date)
	}
`;

const ADD_TRANSLATION = gql`
	mutation addTranslation($original: String, $replacement: String) {
	  addTranslation(original: $original, replacement: $replacement) {
		original
		replacement
	  }
	}
`;
const DELETE_TRANSLATION = gql`
	mutation deleteTranslation($original: String) {
	  deleteTranslation(original: $original)
	}
`;
const EDIT_TOOLTIP = gql`
	mutation editTooltip($glvl: String, $scid: Int, $tooltip: String) {
	  editTooltip(glvl: $glvl, scid: $scid, tooltip: $tooltip)
	}
`;

class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.title = "Fuji - Admin";
		this.state = {confirmTransitionShow:false, confirmInput:"", nextTermNumber:null, nextTermYear:null, isAdmin:false, adminLoaded:false,
				motd: "", motdUnchanged:true, dueTitle:"", dueDate:"", dueUnchanged:true,original:"",replacement:"",translations:[], categories:[], tooltips:{}};

		this.handleConfirmClose = this.handleConfirmClose.bind(this);
		this.handleConfirmShow = this.handleConfirmShow.bind(this);
		this.confirmTextChange = this.confirmTextChange.bind(this);
		this.transitionTerm = this.transitionTerm.bind(this);

		this.motdTextChange = this.motdTextChange.bind(this);
		this.setMOTD = this.setMOTD.bind(this);
		this.handleDueChange = this.handleDueChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.setDue = this.setDue.bind(this);
		
		this.handleOriginalChange = this.handleOriginalChange.bind(this);
		this.handleReplacementChange = this.handleReplacementChange.bind(this);
		this.addTranslation = this.addTranslation.bind(this);
		this.deleteTranslation = this.deleteTranslation.bind(this);
	}
	
	
	async componentDidMount() {
		const idTokenPayload = (await Auth.currentSession()).getIdToken().payload;
		if (idTokenPayload["cognito:groups"]) {
			if (idTokenPayload["cognito:groups"].includes("fujiadmin")) {
				this.setState({isAdmin:true, adminLoaded:true});
			} else this.setState({adminLoaded:true});
		} else this.setState({adminLoaded:true});
		this.mounted = true;
		
		while(this.mounted && !this.props.data.hasOwnProperty("getCurrentTerm"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			let nextTermNumber;
			let nextTermYear;
			if (this.props.data.getCurrentTerm.term_number<3) {
				nextTermNumber=this.props.data.getCurrentTerm.term_number+1;
				nextTermYear=this.props.data.getCurrentTerm.term_year;
			} else {
				nextTermNumber=1;
				nextTermYear=this.props.data.getCurrentTerm.term_year+1;
			}

			let dueDate;
			if (this.props.data.getHomeData.due_date!="") {
				dueDate = new Date(this.props.data.getHomeData.due_date);
			} else dueDate = Date.now();

			let tooltips = {};
			this.props.data.reportCategories.forEach((category) =>  {
				category.subcategories.forEach((subcategory) => {
					tooltips[subcategory.subcategory_id] = {};
					subcategory.grades.forEach((grade) => {
						tooltips[subcategory.subcategory_id][grade.grade_level] = "";
					});
				});
			});
					
			this.props.data.getTooltips.forEach((tooltip) =>  {
				if (tooltips[tooltip.subcategory_id]) {
					tooltips[tooltip.subcategory_id][tooltip.grade_level] = tooltip.tooltip;
				}
			});
			
			this.setState({nextTermNumber: nextTermNumber,
							nextTermYear: nextTermYear,
							motd: this.props.data.getHomeData.motd,
							dueTitle: this.props.data.getHomeData.due_title,
							dueDate: dueDate,
							translations: this.props.data.getTranslations,
							categories: this.props.data.reportCategories,
							tooltips:tooltips});
		}
	}
	

	
	handleConfirmClose() {
		this.setState({confirmTransitionShow: false});
	}
	handleConfirmShow(){
		this.setState({confirmTransitionShow: true});
	}
	

	
	confirmTextChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({confirmInput: value});
	}

	motdTextChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({motd: value, motdUnchanged: false});
	}
	
	setMOTD() {
		this.onSetmotdMutate(this.state.motd);
		this.setState({motdUnchanged: true});
	}
	
	handleDateChange(date){
		this.setState({dueDate: date, dueUnchanged: false});
	}
	handleDueChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({dueTitle: value, dueUnchanged: false});
	}
	
	setDue() {
		this.onSetDueMutate(this.state.dueTitle, this.state.dueDate.toString());
		this.setState({dueUnchanged: true});
	}
	
	handleOriginalChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({original: value});
	}
	handleReplacementChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({replacement: value});
	}
	
	async addTranslation() {
		const {data} = await this.onAddTranslateMutate(this.state.original,this.state.replacement);
		const translations = this.state.translations;
		translations.push(data.addTranslation);
		this.setState({translations: translations, original:"", replacement:""});
	}
	
	deleteTranslation(original) {
		this.onDeleteTranslateMutate(original);
		const translations = this.state.translations;
		const index = translations.findIndex(x => x.original == original.trim());
		if (index > -1) {
			translations.splice(index, 1);
			this.setState({translations: translations});
		}
	}
	
	transitionTerm() {
		this.setState({confirmTransitionShow: false,confirmInput:""});
		this.onTransitionMutate();
		
		let nextTermNumber;
		let nextTermYear;
		if (this.state.nextTermNumber<3) {
			nextTermNumber=this.state.nextTermNumber+1;
			nextTermYear=this.state.nextTermYear;
		} else {
			nextTermNumber=1;
			nextTermYear=this.state.nextTermYear+1;
		}
		
		this.setState({nextTermNumber: nextTermNumber,
						nextTermYear: nextTermYear});
	}

  render() {
	  let adminPage;
	  if (this.state.adminLoaded) {

		if (this.state.isAdmin) {
			adminPage = <Container>
						<div className="admin-row">
							<h3>Front Page Notice</h3>
							<Mutation mutation={SET_MOTD}>
									{(setmotdMutate) => {
										this.onSetmotdMutate = (motd) => setmotdMutate( {variables: { motd } } )
										return (
											null
										)
									}
								}
								</Mutation>
							<Form.Control as="textarea" rows={3} onChange={this.motdTextChange} value={this.state.motd}/>
							<div className="d-flex justify-content-center mt-1">
							<Button variant="primary" type="submit" onClick={this.setMOTD} disabled={this.state.motdUnchanged}>Submit</Button>
							</div>
						</div>
						
						<div className="admin-row">
							<h3>Due Countdown</h3>
							<Mutation mutation={SET_DUE}>
									{(setDueMutate) => {
										this.onSetDueMutate = (due_title,due_date) => setDueMutate( {variables: { due_title,due_date } } )
										return (
											null
										)
									}
								}
								</Mutation>
							<div className="d-flex flex-wrap">
								<div className="d-flex flex-column admin-due-text">
									<Form.Label>Due Text</Form.Label>
									<input type="text" value={this.state.dueTitle} onChange={this.handleDueChange}/>
								</div>
								<div className="d-flex flex-column">
									<Form.Label>Due Date</Form.Label>
									<DatePicker selected={this.state.dueDate} onChange={(date) => this.handleDateChange(date)} showTimeInput/>
								</div>
								<div className="admin-button-pos">
									<Button className="mt-1" variant="primary" type="submit" onClick={this.setDue} disabled={this.state.dueUnchanged}>Submit</Button>
								</div>
							</div>
						
						</div>
						
						<div className="admin-row">
							<h3>Custom Translations</h3>
							<Mutation mutation={ADD_TRANSLATION}>
									{(addTranslateMutate) => {
										this.onAddTranslateMutate = (original,replacement) => addTranslateMutate( {variables: { original,replacement } } )
										return (
											null
										)
									}
								}
							</Mutation>
							<Mutation mutation={DELETE_TRANSLATION}>
									{(deleteTranslateMutate) => {
										this.onDeleteTranslateMutate = (original) => deleteTranslateMutate( {variables: { original } } )
										return (
											null
										)
									}
								}
							</Mutation>
							<div className="d-flex flex-wrap">
								<div className="d-flex flex-column admin-due-text">
									<Form.Label>Original</Form.Label>
									<input type="text" value={this.state.original} onChange={this.handleOriginalChange}/>
								</div>
								<div className="d-flex flex-column">
									<Form.Label>Replacement</Form.Label>
									<input type="text" value={this.state.replacement} onChange={this.handleReplacementChange}/>
								</div>
								<div className="admin-button-pos">
									<Button className="mt-1" variant="success" type="Add" onClick={this.addTranslation} disabled={(this.state.original=="" || this.state.replacement=="")}>Add</Button>
								</div>
							</div>
							<div className="d-flex flex-column mt-3">
								{this.state.translations.map((translation) =>
									<div className="d-flex align-items-center" key={translation.original}>
										<div className="d-flex admin-translate-word">{translation.original}</div>
										<div>to</div>
										<div className="d-flex admin-translate-word">{translation.replacement}</div>
										<Button className="mt-1" size="sm" variant="danger" type="Add" onClick={this.deleteTranslation.bind(this,translation.original)} >Delete</Button>
									</div>
									)}
							</div>
						
						</div>

						<div className="admin-row">
							<h3>Tooltips</h3>
							<Accordion>
								{this.state.categories.map((category) =>  
									<Card key={"C"+category.category_id}>
										<Accordion.Toggle as={Card.Header} eventKey={"EK"+category.category_id} className="archive-term" style={{backgroundColor: category.colour}}>
											{category.category_en}
										</Accordion.Toggle>
										<Accordion.Collapse eventKey={"EK"+category.category_id}>
											<Card.Body className="admin-tooltip-cat">
												{category.subcategories.map((subcategory) =>  
													<div className="admin-tooltip-subcat" key={"SC"+subcategory.subcategory_id}>
														<h5>{subcategory.subcategory_en}</h5>
														<GradeTooltip grade_level="X" scid={parseInt(subcategory.subcategory_id)} tooltip={this.state.tooltips[subcategory.subcategory_id]["X"]}/>
														{subcategory.grades.map((grade) =>
															<GradeTooltip grade_level={String(grade.grade_level)} scid={parseInt(subcategory.subcategory_id)} 
																tooltip={this.state.tooltips[subcategory.subcategory_id][String(grade.grade_level)]} key={"G"+grade.grade_level}/>
															
														)}
													</div>
												)}
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								)}
								
							</Accordion>
						</div>

						<div className="admin-row mt-5">
							<h3>Transition Term</h3>
								<Mutation mutation={TRANSITION_TERM}>
										{(transitionMutate) => {
											this.onTransitionMutate = () => transitionMutate( {variables: {  } } )
											return (
												null
											)
										}
									}
									</Mutation>
								<Modal show={this.state.confirmTransitionShow} onHide={this.handleConfirmClose} animation={false} centered>
										<Modal.Header closeButton>
											<Modal.Title>Confirm Term Transition</Modal.Title>
										</Modal.Header>
										<Modal.Body>Are you sure you want to advance to Term{this.state.nextTermNumber && <> {this.state.nextTermNumber}, {this.state.nextTermYear}</>}?
										<p>Type <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>confirm</span> to confirm</p></Modal.Body>
										
											<Form.Control
												className="class-delete-input"
											aria-label="delete student"
											onChange={this.confirmTextChange}
											value={this.state.confirmInput}
											/>
										<Modal.Footer>
										<Button variant="secondary" onClick={this.handleConfirmClose}>Cancel</Button>
										<Button variant="success" onClick={this.transitionTerm} disabled={this.state.confirmInput!='confirm'}>Confirm</Button>
										</Modal.Footer>
									</Modal>
									<Col className="d-flex justify-content-center">
										<Button variant="success" size="lg" onClick={this.handleConfirmShow}>Advance Term</Button>
								</Col>
							</div>
					</Container>
		} else adminPage = <Redirect to="/" />;
	  }
	  return (
		<div className="active">
			{adminPage}
			

		</div>
	  );
	}
}

class GradeTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {tooltip: this.props.tooltip};
		this.handleTooltipChange = this.handleTooltipChange.bind(this);
		this.typingTimer = null;
		
	}
	
	async componentWillUnmount() {
		if (this.typingTimer) {
			this.onEditTooltipMutate( this.props.grade_level, this.props.scid, this.state.tooltip );
			clearTimeout(this.typingTimer);
		}
	}
	
	handleTooltipChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({tooltip: value});
		clearTimeout(this.typingTimer);
		
		this.typingTimer = setTimeout(() => {
			this.onEditTooltipMutate( this.props.grade_level, this.props.scid, value );
			this.typingTimer = null;
		}, 1000);
		
	}
	
	render() {
		return (
			<div className="admin-tooltip-grade">
					<Mutation mutation={EDIT_TOOLTIP}>
							{(editTooltipMutate) => {
								this.onEditTooltipMutate = (glvl,scid,tooltip) => editTooltipMutate( {variables: { glvl,scid,tooltip } } )
								return (
									null
								)
							}
						 }
					</Mutation>
				<div className="admin-tooltip-grade-level">{this.props.grade_level}</div>
				<TextareaAutosize value={this.state.tooltip} onChange={this.handleTooltipChange} />
			</div>
		);
	}
}

export default graphql(GET_TERM, {
  options: { fetchPolicy: 'network-only' },
})(Admin);
