import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { gql } from 'apollo-boost';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import {LinkContainer} from 'react-router-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import translate from "translate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Auth, Storage } from 'aws-amplify';
import throttle from 'lodash.throttle';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { homeURL } from './homeurl';
import { S3Image } from 'aws-amplify-react';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
	MPLUS: {
		normal: homeURL+'/fonts/mplus/MPLUS1p-Regular.ttf',
		bold: homeURL+'/fonts/mplus/MPLUS1p-Bold.ttf',
		italics: homeURL+'/fonts/mplus/MPLUS1p-Regular.ttf',
		bolditalics: homeURL+'/fonts/mplus/MPLUS1p-Bold.ttf',
	  }
}

pdfMake.tableLayouts = {
  gradesLayout: {
    hLineWidth: function (i, node) {
      if (i === 0 || i === node.table.body.length) {
        return 0.5;
      }
      return (i === node.table.headerRows) ? 0.5 : 0.5;
    },
    vLineWidth: function (i) {
      return 0.5;
    },
    hLineColor: function (i) {
      return i === 1 ? 'black' : 'black';
    },
    paddingLeft: function (i) {
      return i === 0 ? 8 : 8;
    },
    paddingRight: function (i, node) {
      return (i === node.table.widths.length - 1) ? 8 : 8;
    }
  },
  detailsLayout: {
    hLineWidth: function (i, node) {
        return 0.5;
    },
    vLineWidth: function (i) {
      return 0.5;
    },
    hLineColor: function (i) {
      return i === 1 ? 'black' : 'black';
    },
    paddingLeft: function (i) {
      return 8;
    },
    paddingRight: function (i, node) {
      return 8;
    },
	paddingBottom: function (i) {
      return 6;
    },
    paddingTop: function (i, node) {
      return 6;
    }
  },
  commentsLayout: {
    hLineWidth: function (i, node) {
      return 0.5;
    },
    vLineWidth: function (i) {
      return 0.5;
    },
    hLineColor: function (i) {
      return i === 1 ? 'black' : 'black';
    },
    paddingLeft: function (i) {
      return 8;
    },
    paddingRight: function (i, node) {
      return 8;
    },
	paddingBottom: function (i) {
      return 3;
    },
    paddingTop: function (i, node) {
      return 3;
    }
  },
  imageLayout: {
    hLineWidth: function (i, node) {
      return 0;
    },
    vLineWidth: function (i) {
      return 0;
    }
  },
  headingLayout: {
    paddingLeft: function (i) {
      return 2;
    },
    paddingRight: function (i) {
      return 2;
    },
	paddingBottom: function (i) {
		if (i===0) return 0;
      else return 2;
    },
	paddingTop: function (i) {
		if (i===1) return 0;
		else return 2;
    }
  },
  tableLayout: {
    paddingLeft: function (i) {
      return 2;
    },
    paddingRight: function (i) {
      return 2;
    },
	paddingBottom: function (i) {
		return 2;
    },
	paddingTop: function (i) {
		return 2;
    }
  }
};
const GET_STUDENT = gql`
  query getStudentRecords($stid: Int) {
    getStudentRecords(stid: $stid) {
		student_id
		japanese_name
		english_name
		exclude_avg_toggle
		photo
		notes{
			note_id
			note_en
			note_jp
			author
			posting_date
		}
		externals{
			external_id
			description
			external_link
		}
		subcategories{
			subcategory_id
			subcategory_en
			subcategory_max
			colour
		}
		terms {
			term_id
			term_year
			term_number
			term_active
			grades {
					subcategory_id
					grade_level
				}
			averages {
					subcategory_id
					grade_level
					subcategory_max
				}		
			comment_en
			comment_jp
			class_teacher
			class_id
			class_name
		}
	}
	getTranslations{
		original
		replacement
	}
  }
`;

const ADD_RECORD_NOTE = gql`
	mutation addRecordNote($stid: Int, $nen: String, $njp: String, $author: String, $pdate: String) {
	  addRecordNote(stid: $stid, nen: $nen, njp: $njp, author: $author, pdate:$pdate) {
			note_id
			note_en
			note_jp
			author
			posting_date
	  }
	}
`;
const DELETE_RECORD_NOTE = gql`
	mutation deleteRecordNote($nid: Int) {
	  deleteRecordNote(nid: $nid)
	}
`;
const EDIT_RECORD_NOTE = gql`
	mutation editRecordNote($nid: Int, $stid: Int, $nen: String, $njp: String, $author: String, $pdate: String) {
	  editRecordNote(nid: $nid, stid: $stid, nen: $nen, njp: $njp, author: $author, pdate:$pdate) {
			note_id
			note_en
			note_jp
			author
			posting_date
	  }
	}
`;
const ADD_RECORD_EXTERNAL = gql`
	mutation addRecordExternal($stid: Int, $desc: String, $link: String) {
	  addRecordExternal(stid: $stid, desc: $desc, link: $link) {
			external_id
			description
			external_link
	  }
	}
`;
const DELETE_RECORD_EXTERNAL = gql`
	mutation deleteRecordExternal($eid: Int) {
	  deleteRecordExternal(eid: $eid)
	}
`;
const DELETE_STUDENT = gql`
	mutation deleteStudent($stid: Int) {
	  deleteStudent(stid: $stid)
	}
`;
const EDIT_NAME_EN = gql`
	mutation editStudentNameEn($stid: Int, $nen: String) {
	  editStudentNameEn(stid: $stid, nen: $nen)
	}
`;
const EDIT_NAME_JP = gql`
	mutation editStudentNameJp($stid: Int, $njp: String) {
	  editStudentNameJp(stid: $stid, njp: $njp)
	}
`;
const EDIT_EXCLUDE_AVG = gql`
	mutation editExcludeAvg($stid: Int, $exclude: Boolean) {
	  editExcludeAvg(stid: $stid, exclude: $exclude) {
			term_id
			term_year
			term_number
			term_active
			grades {
					subcategory_id
					grade_level
				}
			averages {
					subcategory_id
					grade_level
					subcategory_max
				}	
			comment_en
			comment_jp
			class_teacher
			class_id
			class_name	
	  }
	}
`;
const EDIT_PHOTO = gql`
	mutation editPhoto($stid: Int, $photo: Boolean) {
	  editPhoto(stid: $stid, photo: $photo)
	}
`;

const STUDENT_REPORT = gql`
	query getStudentReport($stid: Int, $clid: Int) {
		getStudentReport(stid: $stid, clid: $clid) {
			class_name
			class_comment_en
			class_comment_jp
			class_teacher
			class_unit
			term_year
			term_number
			
			details {
				japanese_name
				english_name
			}
			grades {
				subcategory_id
				grade_level
			}
			comment_en
			comment_jp
			
			categories{
				category_id
				category_en
				category_jp
				colour
				subcategories{
					subcategory_id
					subcategory_en
					subcategory_jp
					subcategory_max
					grades{
						grade_level
						grade_jp
					}
				}
			}
		}
	}
`;

class StudentRecord extends React.Component {
	constructor(props) {
		super(props);
		this.state = { englishName:"", japaneseName:"", studentId:0, excludeAvg:false, subcategories:[], terms:[], notes:[], externals:[],
			noteModalShow: false,noteEn:"",noteJp:"", translateLoading:false, noteAuthor:"", noteDate:new Date(), newNotePanelDisplayed:false,
			externalModalShow: false, externalDesc: "", externalLink: "", photo:false, photoUpdate:false, deletePhotoShow: false, 
			deleteStudentShow:false, deleteInput:"", loading:true, isAdmin: false, currentActiveTerm:-1, headerTop:0, showChart:false, gradeChanges:[], 
			chartOptions: {
				xAxis: {
				  categories: ['', '', ''],
				  title:{text:"Term"}
				},
				yAxis: {
				  max: 10,
				  allowDecimals:false,
				  title:{text:"Score"}
				},
				series: [
				  { data: [] }
				],
				credits:{enabled:false},
				legend:{enabled:true},
				title:{text:""},
			  },
			  translations:[], simComment:"o",simFont:9, simClassComment:"", simCommentJp:"", simClassCommentJp:""};
		this.handleNoteShow = this.handleNoteShow.bind(this);
		this.handleNoteClose = this.handleNoteClose.bind(this);	
		this.newNotePanelShow = this.newNotePanelShow.bind(this);
		this.newNotePanelHide = this.newNotePanelHide.bind(this);
		this.handleNoteEnChange = this.handleNoteEnChange.bind(this);
		this.handleNoteJpChange = this.handleNoteJpChange.bind(this);	
		this.handleNoteAuthorChange = this.handleNoteAuthorChange.bind(this);	
		this.handleDateChange = this.handleDateChange.bind(this);	
		this.createNote = this.createNote.bind(this);	
		this.removeNote = this.removeNote.bind(this);	
		
		this.handleExternalShow = this.handleExternalShow.bind(this);
		this.handleExternalClose = this.handleExternalClose.bind(this);		
		this.createExternal = this.createExternal.bind(this);	
		this.removeExternal = this.removeExternal.bind(this);	
		this.handleExternalDescChange = this.handleExternalDescChange.bind(this);	
		this.handleExternalLinkChange = this.handleExternalLinkChange.bind(this);	
		
		this.handleDeleteClose = this.handleDeleteClose.bind(this);
		this.handleDeleteShow = this.handleDeleteShow.bind(this);	
		this.deleteTextChange = this.deleteTextChange.bind(this);	
		this.deleteStudent = this.deleteStudent.bind(this);	
		this.translateToJp = this.translateToJp.bind(this);
		
		this.typingTimerNameEn = null;
		this.typingTimerNameJp = null;
		this.handleNameEnChange = this.handleNameEnChange.bind(this);
		this.handleNameJpChange = this.handleNameJpChange.bind(this);

		this.handleScroll = this.handleScroll.bind(this);
		this.throttledFunc = throttle(this.handleScroll,100);
		this.refTop = React.createRef();
		this.refBottom = React.createRef();

		this.displayGraph = this.displayGraph.bind(this);
		this.activateTerm = this.activateTerm.bind(this);

		this.generateReports = this.generateReports.bind(this);

		this.handleExcludeChange = this.handleExcludeChange.bind(this);

		this.handlePhotoChange = this.handlePhotoChange.bind(this);
		this.handleDeletePhoto = this.handleDeletePhoto.bind(this);
		this.handleDeletePhotoClose = this.handleDeletePhotoClose.bind(this);
		this.handleDeletePhotoShow = this.handleDeletePhotoShow.bind(this);

		this.editNote = this.editNote.bind(this);

		this.simRef = React.createRef();
	}
	
	async componentDidMount() {
		window.addEventListener('scroll', this.throttledFunc);
		this.mounted = true;
		const idTokenPayload = (await Auth.currentSession()).getIdToken().payload;
		if (idTokenPayload["cognito:groups"]) {
			if (idTokenPayload["cognito:groups"].includes("fujiadmin")) {
				this.setState({isAdmin:true});
			}
		}
		while(this.mounted && !this.props.data.hasOwnProperty("getStudentRecords"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			
			this.setState({englishName: this.props.data.getStudentRecords.english_name,
				japaneseName: this.props.data.getStudentRecords.japanese_name,
				studentId: this.props.data.getStudentRecords.student_id,
				excludeAvg: this.props.data.getStudentRecords.exclude_avg_toggle,
				subcategories: this.props.data.getStudentRecords.subcategories,
				terms: this.props.data.getStudentRecords.terms,
				notes: this.props.data.getStudentRecords.notes,
				externals: this.props.data.getStudentRecords.externals,
				photo: this.props.data.getStudentRecords.photo
				});

			

			let gradeChanges = [];
			for (let i=0; i < this.props.data.getStudentRecords.terms.length; i++) {
				let gradeChangesRow = {};
				if (this.props.data.getStudentRecords.terms[i+1]) {
					this.props.data.getStudentRecords.terms[i].grades.forEach((grade) =>  {
						let previousGradeIndex = this.props.data.getStudentRecords.terms[i+1].grades.findIndex(x => x.subcategory_id == grade.subcategory_id);
						if (previousGradeIndex>-1) {
							
							if (parseInt(grade.grade_level)>parseInt(this.props.data.getStudentRecords.terms[i+1].grades[previousGradeIndex].grade_level)) {
								gradeChangesRow[grade.subcategory_id] = 1;
							} else if (parseInt(grade.grade_level)<parseInt(this.props.data.getStudentRecords.terms[i+1].grades[previousGradeIndex].grade_level)) {
								gradeChangesRow[grade.subcategory_id] = -1;
							} else gradeChangesRow[grade.subcategory_id] = 0;
							
						} else gradeChangesRow[grade.subcategory_id] = 0;
					});	
				}
				gradeChanges.push(gradeChangesRow);
			}
			this.setState({gradeChanges:gradeChanges});

			document.title = "Fuji - "+this.props.data.getStudentRecords.english_name;
			this.setState({translations: this.props.data.getTranslations});
			this.setState({loading: false});
		}
	}
	
	async componentWillUnmount() {
		window.removeEventListener('scroll', this.throttledFunc);
		if (this.typingTimerNameEn) {
			this.onEditNameEnMutate( parseInt(this.state.studentId), this.state.englishName )
			clearTimeout(this.typingTimerNameEn);
		}
		if (this.typingTimerNameJp) {
			this.onEditNameJpMutate( parseInt(this.state.studentId), this.state.japaneseName )
			clearTimeout(this.typingTimerNameJp);
		}
	}
	
	handleScroll(event) {
		let scrollTop = event.target.documentElement.scrollTop;

		let tableTop = this.refTop.current.offsetTop;
		let tableBottom = tableTop+this.refBottom.current.offsetTop-550;

		if (scrollTop>tableTop) {
			if (scrollTop<tableBottom) {
				this.setState({headerTop:scrollTop-tableTop});
			}
			
		} else {
			this.setState({headerTop:0});
		}
	}

	handleNoteClose() {
		this.setState({noteModalShow: false});
	}
	handleNoteShow(){
		this.setState({noteModalShow: true});
	}
	
	
	handleNoteEnChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({noteEn: value});
	}
	
	handleNoteJpChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({noteJp: value});
	}	
	handleDateChange(date){
		this.setState({noteDate: date});
	}
	handleNoteAuthorChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({noteAuthor: value});
	}
	newNotePanelShow() {
		this.setState({newNotePanelDisplayed: true});
	}
	newNotePanelHide() {
		this.setState({newNotePanelDisplayed: false});
	}
	async createNote() {
		const nen = this.state.noteEn;
		const njp = this.state.noteJp;
		const author = this.state.noteAuthor;
		
		this.setState({newNotePanelDisplayed: false, noteEn:"",noteJp:"",noteAuthor:""});
		const { data } = await this.onAddRecordMutate(parseInt(this.state.studentId), nen, njp, author, this.state.noteDate.toISOString());
		
		if (data.addRecordNote) {
			this.setState({notes: [data.addRecordNote, ...this.state.notes]});
		}
		
	}

	editNote(note){
		const notes = this.state.notes;
		const index = notes.findIndex(x => x.note_id == parseInt(note.note_id));
		if (index > -1) {
			notes[index] = note;
			this.setState({notes: notes});
		}
	}
	
	removeNote(nid) {
		this.onDeleteRecordMutate( parseInt(nid) );
		const notes = this.state.notes;
		const index = notes.findIndex(x => x.note_id == parseInt(nid));
		if (index > -1) {
			notes.splice(index, 1);
			this.setState({notes: notes});
		}
		
	}
	
	async translateToJp() {
		this.setState({translateLoading: true});
		
		let tempComment = this.state.noteEn;

		this.state.translations.forEach((translation) => {
			
			const regex = new RegExp('\\b' + translation.original + '\\b', "g");
			tempComment = tempComment.replaceAll(regex, "<Q"+translation.original.replace(/ /g,'')+"Q>");	
		});
		
		let jp = await translate(tempComment, "ja");
		
		this.state.translations.forEach((translation) => {
			jp = jp.replaceAll("<Q"+translation.original.replace(/ /g,'')+"Q>", translation.replacement);
		});
		
		this.setState({noteJp: jp});
		this.setState({translateLoading: false});
		
	}
	
	
	
	
	handleExternalClose() {
		this.setState({externalModalShow: false});
	}
	handleExternalShow(){
		this.setState({externalModalShow: true});
	}
	handleExternalLinkChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({externalLink: value});
	}
	handleExternalDescChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({externalDesc: value});
	}
	async createExternal() {
		const desc = this.state.externalDesc;
		const link = this.state.externalLink;
		this.setState({externalDesc:"",externalLink:""});
		const { data } = await this.onAddExternalMutate(parseInt(this.state.studentId), desc, link);
		
		if (data.addRecordExternal) {
			this.setState({externals: [...this.state.externals, data.addRecordExternal]});
		}
	}
	removeExternal(eid) {
		this.onDeleteExternalMutate( parseInt(eid) );
		const externals = this.state.externals;
		const index = externals.findIndex(x => x.external_id == parseInt(eid));
		if (index > -1) {
			externals.splice(index, 1);
			this.setState({externals: externals});
		}
	}

	handleDeleteClose() {
		this.setState({deleteStudentShow: false});
		}
	handleDeleteShow(){
		this.setState({deleteStudentShow: true});
	}
	

	
	deleteTextChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({deleteInput: value});
	}
	
	deleteStudent() {
		this.onDeleteStudentMutate( parseInt(this.state.studentId) );
		this.props.history.push('/student');
	}


	handleNameEnChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({englishName: value});
		clearTimeout(this.typingTimerNameEn);
		this.typingTimerNameEn = setTimeout(() => {
			this.onEditNameEnMutate( parseInt(this.state.studentId), value )
			this.typingTimerNameEn = null;
		}, 1000);
	}	
	
	handleNameJpChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({japaneseName: value});
		clearTimeout(this.typingTimerNameJp);
		this.typingTimerNameJp = setTimeout(() => {
			this.onEditNameJpMutate( parseInt(this.state.studentId), value )
			this.typingTimerNameJp = null;
		}, 1000);
	}	

	async handleExcludeChange(event) {
		const target = event.target;
		const value = target.checked;
		this.setState({excludeAvg: value});
		
		const {data} = await this.onEditExcludeMutate( parseInt(this.state.studentId), value );
		this.setState({terms: data.editExcludeAvg});
	}	

	async handlePhotoChange(event) {

		const file = event.target.files[0];
		const dis = this;
		try {
		  await Storage.put("st"+this.state.studentId, file, {
			contentType: 'image/*'
		  });

		  this.onEditPhotoMutate( parseInt(this.state.studentId), true )
		  this.setState({photo:true, photoUpdate: !this.state.photoUpdate});
		} catch (error) {
		  console.log('Error uploading file: ', error);
		}  
		
	}

	async handleDeletePhoto() {
		await Storage.remove("st"+this.state.studentId);
		this.onEditPhotoMutate( parseInt(this.state.studentId), false );
		this.setState({photo: false});
	}
	
	handleDeletePhotoClose() {
		this.setState({deletePhotoShow: false});
	}
	handleDeletePhotoShow() {
		this.setState({deletePhotoShow: true});
	}

	displayGraph(scid,scen,smax) {
		this.setState({showChart: true});
		let data = [];
		let avgdata = [];
		let categories = [];
		this.state.terms.forEach((term) =>  {
			const index = term.grades.findIndex(x => x.subcategory_id == scid);
			let graded = true;
			if (index > -1) {
				if (term.grades[index].grade_level == "" || term.grades[index].grade_level == "X") {
					graded = false;
				} else {
					data.unshift(parseInt(term.grades[index].grade_level));
					categories.unshift(term.term_year+"/"+term.term_number);
				}
			} else graded = false;
			
			const avgindex = term.averages.findIndex(x => x.subcategory_id == scid);
			if (avgindex > -1) {
				if (term.averages[avgindex].grade_level == "" || term.averages[avgindex].grade_level == "X" || term.averages[avgindex].grade_level == 0) {
					if (graded) avgdata.unshift(null);
				} else {
					if (!graded) categories.unshift(term.term_year+"/"+term.term_number);
					avgdata.unshift(parseFloat(term.averages[avgindex].grade_level));
				}
			} else {
				if (graded) avgdata.unshift(null);
			}
			
		});
		const chartOptions = {
			xAxis: {
			  categories: categories,
			  title:{text:"Term"}
			},
			yAxis: {
			  min:0,
			  max: smax,
			  allowDecimals:false,
			  title:{text:"Score"}
			},
			series: [
				{ name: "Average", color: "#434348", data: avgdata },
				{ name: this.state.englishName, color: "#7cb5ec", data: data }
			],
			credits:{enabled:false},
			legend:{enabled:true},
			title:{text:scen},
		  };
		this.setState({chartOptions: chartOptions});
	}

	activateTerm(tid) {
		this.setState({currentActiveTerm: tid});
	}
	
	async generateReports(stid,clid){
		
		const {data} = await this.props.client.query({
		  query: STUDENT_REPORT,
		  variables: { stid: parseInt(stid), clid: parseInt(clid) },
		  fetchPolicy: 'network-only',
		});
		const index = this.state.terms.findIndex(x => x.class_id == parseInt(clid));
		let averages;
		if (index > -1) {
			averages = this.state.terms[index].averages;
		} else return null;
		
		let avgs = {}
		if (averages) {
			averages.forEach(average => {
				avgs[average.subcategory_id] = average.grade_level;
				});	
		}
		
		let pages = [];
		let firstStudent = true;

		let classUnit = data.getStudentReport.class_unit;
		if (!data.getStudentReport.class_unit) {
			classUnit = "-";
		}

		let page2t = {layout: 'imageLayout', margin: [0, 0, 0, 20],pageBreak: 'before',
					table: {
							headerRows: 1,
							widths: [ '100%' ],
				
							body: [
								[ {image: 'success', width:'260', alignment:'center'}]
							]
						}
					};

		let page2a = {layout: 'detailsLayout',
			table: {
				headerRows: 0,
				widths: [ '23%', '31%', '21%', '25%' ],
				
				body: [
					[ {text:'Class',alignment:'center', style:'nameHeader'}, {text:'English name',alignment:'center', style:'nameHeader'}, {text:'', border: [false, false, false, false]},
						{text:'Term '+data.getStudentReport.term_number+', '+data.getStudentReport.term_year,alignment:'center', style:'termHeader', border: [true, true, true, false]}],
						
					[ {text:data.getStudentReport.class_name,alignment:'center'}, {text:data.getStudentReport.details.english_name,alignment:'center'},{text:'', border: [false, false, false, false]},{text:'', border: [true, false, true, true]}],
					
					[ {text:'Teacher',alignment:'center', style:'nameHeader'}, {text:'Japanese name',alignment:'center', style:'nameHeader'},{text:'', border: [false, false, false, false]} , {text:'Unit',alignment:'center'}],
					
					[ {text:data.getStudentReport.class_teacher,alignment:'center'}, {text:data.getStudentReport.details.japanese_name,alignment:'center'}, {text:'', border: [false, false, false, false]},
						{text:classUnit,alignment:'center'}]
				]
			  }
			
			};


		let commentFontSize = 9;
		this.setState({simComment: data.getStudentReport.comment_en,simFont: commentFontSize, simClassComment: data.getStudentReport.class_comment_en,
						simCommentJp: data.getStudentReport.comment_jp, simClassCommentJp: data.getStudentReport.class_comment_jp});
		
		while (this.simRef.current.clientHeight>420) {
			commentFontSize = commentFontSize-0.3;
			this.setState({simFont: commentFontSize});
		}

		let page2b = {layout: 'commentsLayout', margin: [0, 20, 0, 0],
			table: {
					headerRows: 0,
					heights: ['auto',100,'auto',100],
					widths: [ '50%', '50%' ],
					body: [
						[ {text:'Class Comment', style:'commentHeader',fillColor: '#f0afdf',alignment:'center'}, {text:'クラスについてのコメント (自動翻訳)', style:'commentHeader',fillColor: '#f0afdf',alignment:'center'}],
						[ {text:data.getStudentReport.class_comment_en, fontSize:commentFontSize}, {text:data.getStudentReport.class_comment_jp, fontSize:commentFontSize}],
						[ {text:'Student Comment', style:'commentHeader',fillColor: '#9fc5e8',alignment:'center'}, {text:'生徒についてのコメント (自動翻訳)', style:'commentHeader',fillColor: '#9fc5e8',alignment:'center'}],
						[ {text:data.getStudentReport.comment_en, fontSize:commentFontSize}, {text:data.getStudentReport.comment_jp, fontSize:commentFontSize} ],
					]
				}
			}
			
		let page2c = {layout: 'imageLayout', margin: [0, 20, 0, 0],
			table: {
					headerRows: 1,
					widths: [ '100%' ],
		
					body: [
						[ {image: 'kobito', width:'150', alignment:'center'}]
					]
				}
			};
		
		let firstCategory = true;
		data.getStudentReport.categories.forEach((category) => {
			let categoryTable;
					
			let headingRow;
			if (!!firstCategory) {
				categoryTable = {layout: 'headingLayout', table: {
					headerRows: 0, 
					widths: [ '25%', '23%', '11%', '11%', '30%' ],
					body: []
					}};
				let startRow = [ {text:'', border: [false, false, false, false]},
					{text:'', border: [false, false, false, false]}, 
					{text:"Average",alignment:'center',style:'avgHeaderEN', border: [true, true, true, false]},
				{text:"Student Ability",style:'categoryHeader',alignment:'center', border: [true, true, true, false], colSpan:2},'']
					
				categoryTable.table.body.push(startRow);
				headingRow = [ {text:category.category_en,style:'categoryHeader',alignment:'center', border: [false, false, false, false]},
					{text:category.category_jp,style:'categoryHeader',alignment:'center', border: [false, false, false, false]}, 
					{text:"クラス平均",alignment:'center',style:'avgHeaderJP', border: [true, false, true, false]},
					{text:"生徒の実力",style:'categoryHeader',alignment:'center', border: [true, false, true, false], colSpan:2},'']
					
				if (!firstStudent) categoryTable.pageBreak = 'before';
				
			} else {
				categoryTable = {layout: 'tableLayout', table: {
					headerRows: 0, 
					widths: [ '25%', '23%', '11%', '11%', '30%' ],
					body: []
					}};
				headingRow = [ {text:category.category_en,style:'categoryHeader',alignment:'center', border: [false, false, false, false]},
					{text:category.category_jp,style:'categoryHeader',alignment:'center', border: [false, false, false, false]}, 
					{text:'', border: [false, false, false, false]},
					{text:'', border: [false, false, false, false], colSpan:2},'' ]
			}
			
			categoryTable.table.body.push(headingRow);
							
			let firstSubcategory = true;
			category.subcategories.forEach((subcategory) =>  {
				
				const index = data.getStudentReport.grades.findIndex(x => x.subcategory_id == subcategory.subcategory_id);
				if (index > -1) {
					
					if (data.getStudentReport.grades[index].grade_level!="X" && data.getStudentReport.grades[index].grade_level!="") {
						const gradeIndex = subcategory.grades.findIndex(x => x.grade_level == parseInt(data.getStudentReport.grades[index].grade_level));
						
						let row = [ {text: subcategory.subcategory_en, fillColor: category.colour,alignment:'center' , style:'subcategoryHeader' }, 
							{text: subcategory.subcategory_jp, fillColor: category.colour,alignment:'center' , style:'subcategoryHeader' }, 
							{text:avgs[subcategory.subcategory_id]+"/"+subcategory.subcategory_max,alignment:'center', style:'gradeNum'},
							{text:data.getStudentReport.grades[index].grade_level+"/"+subcategory.subcategory_max,alignment:'center', style:'gradeNum'},
							{text:subcategory.grades[gradeIndex].grade_jp,alignment:'left', style:'grade'} ]
						
						
						
						categoryTable.table.body.push(row);
					}
					
				}
				
			});
			if (categoryTable.table.body.length > 1) {
				pages.push(categoryTable);
				if (!!firstStudent) firstStudent = false;
				if (!!firstCategory) firstCategory = false;
			}
		});
		pages.push(page2t);
		pages.push(page2a);
		pages.push(page2b);
		pages.push(page2c);
			
		
		
		let docDefinition = { content: pages,
			defaultStyle: {
				font: 'MPLUS'
			  },
			styles: {
			
			topHeader: {
				fontSize: 15,
			},
			categoryHeader: {
				bold: true,
				fontSize: 12,
				color: 'black'
			},
			avgHeaderEN: {
				bold: true,
				fontSize: 11,
				color: 'black',
			},
			avgHeaderJP: {
				bold: true,
				fontSize: 10,
				color: 'black',
			},
			subcategoryHeader: {
				bold: true,
				fontSize: 9,
				color: 'black'
			},
			gradeNum: {
				fontSize: 10,
			},
			grade: {
				fontSize: 9,
			},
			commentHeader: {
				fontSize: 9,
			},
			termHeader: {
				fontSize: 16,
				bold:true
			},
			nameHeader: {
				bold:true
			},
			comment: {
				fontSize: 9,
			},
			commentS: {
				fontSize: 8.5,
			}
			
		},
		images: {
			kobito: homeURL+'/KobitoSmall.png',
			success: homeURL+'/success.png'
		  }
		};

		const today = new Date();
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0');
		const yyyy = today.getFullYear();

		const docTitle = data.getStudentReport.details.english_name + ' - ' + data.getStudentReport.class_name + ' Term ' + data.getStudentReport.term_number + ', ' +
				data.getStudentReport.term_year + ' (' + yyyy + '-' + mm + '-' + dd + ').pdf';
		pdfMake.createPdf(docDefinition).download(docTitle);
	
	}

	render() {
		let translateButton = <Button variant="primary" onClick={this.translateToJp}>Translate</Button>;
		if (this.state.translateLoading == true) {
			translateButton = <Spinner animation="border" variant="primary" />;
		}
		
		let newNotePanel = <div className="d-flex justify-content-center"><Button variant="success" onClick={this.newNotePanelShow}>New Note</Button></div>;
		if (!!this.state.newNotePanelDisplayed) {

			newNotePanel =	<><Form.Label>English</Form.Label>
				<TextareaAutosize value={this.state.noteEn} onChange={this.handleNoteEnChange} className="class-student-comment-modal"/>
				<div className="d-flex justify-content-center">{translateButton}</div>
				<Form.Label>Japanese</Form.Label>
				<TextareaAutosize value={this.state.noteJp} onChange={this.handleNoteJpChange} className="class-student-comment-modal"/>
				<div className="new-note-footer">
					<div className="d-flex flex-column">
						<Form.Label>Date</Form.Label>
						<DatePicker className="new-note-date" selected={this.state.noteDate} onChange={(date) => this.handleDateChange(date)} dateFormat="yyyy-MM-dd"/>
					</div>
					<div className="d-flex flex-column">
						<Form.Label>Author</Form.Label>
						<input type="text" className="new-note-author" value={this.state.noteAuthor} onChange={this.handleNoteAuthorChange}/>
					</div>
				
				</div>
				<div className="d-flex justify-content-center create-note-button">
				<Button variant="secondary" onClick={this.newNotePanelHide}>Cancel</Button>
				<Button variant="success" disabled={(this.state.noteEn=="" || this.state.noteJp=="" || this.state.noteAuthor=="")} onClick={this.createNote}>Create Note</Button>
				</div></>;
		}


		let studentPhoto;
		if (!this.state.isAdmin) {
			if (!!this.state.photo) {
				studentPhoto = <S3Image imgKey={"st"+this.state.studentId} />;
			} 
		} else {
			if (!!this.state.photo) {
				if (!this.state.photoUpdate) {
					studentPhoto = <><Modal show={this.state.deletePhotoShow} onHide={this.handleDeletePhotoClose} animation={false} centered>
										<Modal.Header closeButton>
											<Modal.Title>Confirm Delete</Modal.Title>
										</Modal.Header>
										<Modal.Body>Are you sure you want to delete this photo?</Modal.Body>
										<Modal.Footer>
										<Button variant="secondary" onClick={this.handleDeletePhotoClose} >Cancel</Button>
										<Button variant="danger" onClick={this.handleDeletePhoto} >Delete</Button>
										</Modal.Footer>
									</Modal>
									<label htmlFor="student_photo"><S3Image imgKey={"st"+this.state.studentId} key={"s3img0"}/>
									<input type="file" id="student_photo" name="student_photo" accept="image/png, image/gif, image/jpeg" onChange={this.handlePhotoChange}/>
									<Button className="student-photo-delete" variant="danger" onClick={this.handleDeletePhotoShow}>Delete Photo</Button>
								</label></>;
				} else {
					studentPhoto = <><Modal show={this.state.deletePhotoShow} onHide={this.handleDeletePhotoClose} animation={false} centered>
										<Modal.Header closeButton>
											<Modal.Title>Confirm Delete</Modal.Title>
										</Modal.Header>
										<Modal.Body>Are you sure you want to delete this photo?</Modal.Body>
										<Modal.Footer>
										<Button variant="secondary" onClick={this.handleDeletePhotoClose} >Cancel</Button>
										<Button variant="danger" onClick={this.handleDeletePhoto} >Delete</Button>
										</Modal.Footer>
									</Modal>
									<label htmlFor="student_photo"><S3Image imgKey={"st"+this.state.studentId} key={"s3img1"}/>
									<input type="file" id="student_photo" name="student_photo" accept="image/png, image/gif, image/jpeg" onChange={this.handlePhotoChange}/>
									<Button className="student-photo-delete" variant="danger" onClick={this.handleDeletePhotoShow}>Delete Photo</Button>
								</label></>;
				}
			} else {
				studentPhoto = <label htmlFor="student_photo"><div className="student-photo-placeholder">Upload Photo</div>
									<input type="file" id="student_photo" name="student_photo" accept="image/png, image/gif, image/jpeg" onChange={this.handlePhotoChange}/>
								</label>;
			}
		}


		let studentBlock = <Row className="d-flex justify-content-center"><Spinner animation="border" variant="primary" /></Row>;
		if (!this.state.loading) {
			studentBlock = <><div className="student-details">
					<div style={{position:"absolute",top:0,left:0,zIndex:-1,visibility:"hidden",overflow:"hidden", width:"1px",pointerEvents:"none"}} ref={this.simRef}> 
						<div className="d-flex">
							<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simClassComment}</div>
							<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simClassCommentJp}</div>
						</div>
						<div className="d-flex">
							<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simComment}</div>
							<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simCommentJp}</div>
						</div>
					</div>
				<Mutation mutation={ADD_RECORD_NOTE}>
					{(addRecordMutate) => {
						this.onAddRecordMutate = (stid, nen, njp, author, pdate) => addRecordMutate( {variables: { stid, nen, njp, author, pdate } } )
						return (
							null
						)
					}
				}
				</Mutation>
				<Mutation mutation={DELETE_RECORD_NOTE}>
					{(deleteRecordMutate) => {
						this.onDeleteRecordMutate = (nid) => deleteRecordMutate( {variables: { nid } } )
						return (
							null
						)
					}
				}
				</Mutation>
				<Modal show={this.state.noteModalShow} onHide={this.handleNoteClose} animation={false} centered>
					<Modal.Header closeButton>
					<Modal.Title>Notes on {this.state.englishName}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{newNotePanel}
					</Modal.Body>
					<Modal.Footer>
						{this.state.notes.map((note) =>
							<InternalNote key={note.note_id} noteId={note.note_id} noteEn={note.note_en} noteJp={note.note_jp} author={note.author}
								pdate={note.posting_date} removeFunc={this.removeNote} studentId={this.state.studentId} editFunc={this.editNote}
								translations={this.state.translations} />
						)}
						
					</Modal.Footer>
				</Modal>
				
				
				<Mutation mutation={ADD_RECORD_EXTERNAL}>
					{(addExternalMutate) => {
						this.onAddExternalMutate = (stid, desc, link) => addExternalMutate( {variables: { stid, desc, link } } )
						return (
							null
						)
					}
				}
				</Mutation>
				<Mutation mutation={DELETE_RECORD_EXTERNAL}>
					{(deleteExternalMutate) => {
						this.onDeleteExternalMutate = (eid) => deleteExternalMutate( {variables: { eid } } )
						return (
							null
						)
					}
				}
				</Mutation>
				<Modal show={this.state.externalModalShow} onHide={this.handleExternalClose} animation={false} centered>
					<Modal.Header closeButton>
					<Modal.Title>External record links for {this.state.englishName}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						
					<div className="d-flex flex-column">
						<div className="d-flex align-items-center">
							<Form.Label className="student-external-label">Description:</Form.Label>
							<input className="student-external-input" type="text" value={this.state.externalDesc} onChange={this.handleExternalDescChange}/>
						</div>
						<div className="d-flex student-external-mid align-items-center">
							<Form.Label className="student-external-label">Link:</Form.Label>
							<input className="student-external-input" type="text" value={this.state.externalLink} onChange={this.handleExternalLinkChange}/>
						</div>
						<div className="d-flex justify-content-center">
							<Button variant="success" disabled={(this.state.externalLink=="")} onClick={this.createExternal}>Create Link</Button>
						</div>
					</div>

					

					</Modal.Body>
					<Modal.Footer>
						{this.state.externals.map((external) =>
							<ExternalLink key={external.external_id} externalId={external.external_id} description={external.description} link={external.external_link}
								removeFunc={this.removeExternal}/>
						)}
						
					</Modal.Footer>
				</Modal>
					<Col>
					<Mutation mutation={EDIT_NAME_EN}>
						{(editNameEnMutate) => {
							this.onEditNameEnMutate = (stid,nen) => editNameEnMutate( {variables: { stid, nen } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<Mutation mutation={EDIT_NAME_JP}>
						{(editNameJpMutate) => {
							this.onEditNameJpMutate = (stid,njp) => editNameJpMutate( {variables: { stid, njp } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<Mutation mutation={EDIT_PHOTO}>
						{(editPhotoMutate) => {
							this.onEditPhotoMutate = (stid,photo) => editPhotoMutate( {variables: { stid, photo } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<div className="student-header">
						{studentPhoto}
						<div>
							<h1 className="text-center"><input className="text-center borderless" type="text" name="englishName" placeholder="EN" value={this.state.englishName} onChange={this.handleNameEnChange}/></h1>
							<h1 className="text-center"><input className="text-center borderless" type="text" name="japaneseName" placeholder="JP" value={this.state.japaneseName} onChange={this.handleNameJpChange}/></h1>
						</div>
					</div>
					<div className="mt-3"><Button variant="outline-secondary" onClick={this.handleNoteShow}>Internal Notes</Button></div>
					
					<div className="records-button"><Button variant="outline-secondary" onClick={this.handleExternalShow}>External Records</Button></div>
					<Mutation mutation={EDIT_EXCLUDE_AVG}>
						{(editExcludeMutate) => {
							this.onEditExcludeMutate = (stid,exclude) => editExcludeMutate( {variables: { stid, exclude } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<div className="mt-3 records-exclude-avg">
						<InputGroup>
							<InputGroup.Prepend>
							  <InputGroup.Checkbox aria-label="Checkbox for exclude average" checked={this.state.excludeAvg} onChange={this.handleExcludeChange}/>
							</InputGroup.Prepend>
							<div className="records-exclude-avg-label">Exclude from Averages</div>
						  </InputGroup>
					</div>

					</Col>
				</div>
				<Table hover className="table-header-rotated" style={{marginTop:"178px"}} responsive ref={this.refTop}>
					<thead>
						<tr>
						<th className="records-term">Term</th>
						<th className="records-term">Class</th>
						<th className="records-term">Teacher</th>
						{this.state.subcategories.map((subcat) =>
							<th className="rotate" key={"CH"+subcat.subcategory_id} style={{top:this.state.headerTop+"px"}}>
								<div><span style={{backgroundColor:subcat.colour}}>{subcat.subcategory_en}</span></div>
							</th>
							)}
						<th className="class-student-comment">Comment</th>
						</tr>
					</thead>
					<tbody>
					
						{this.state.terms.map((term, index) =>
							<CourseRow key={"T"+term.term_id} grades={term.grades} subcategories={this.state.subcategories} termYear={term.term_year}
								termId={term.term_id} termNumber={term.term_number} commentEn={term.comment_en} commentJp={term.comment_jp} 
								classId={term.class_id} classTeacher={term.class_teacher} class_name={term.class_name} averages={term.averages} 
								currentActiveTerm={this.state.currentActiveTerm} activeFunc={this.activateTerm} generateFunc={this.generateReports} 
								termActive={term.term_active} studentId={this.state.studentId} gradeChanges={this.state.gradeChanges[index]}/>
							)}

						<tr className="class-student-row">
							<td className="course-names table-borderless"></td>
							<td className="course-names table-borderless"></td>
							<td className="course-names table-borderless"></td>
							{this.state.subcategories.map((subcat) =>
							<td key={"X"+subcat.subcategory_id} className="class-x-all table-borderless"><Button onClick={this.displayGraph.bind(this,subcat.subcategory_id,subcat.subcategory_en,subcat.subcategory_max)}>
							<img className="chart-icon" src={"data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9"+
								"zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldy"+
								"AwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBvbHlnb24gcG9pbnRzPSI5NSw5NSA1LDk1IDUsNSAxNi4yNSw1IDE2LjI1LDgzLjc1IDk1LDgzLjc1ICAiPjwvcG9seWdvbj48cG9seWdvbiBwb"+
								"2ludHM9Ijk0LjE3Myw5LjQ2NyA2MC45ODksNDcuMDIxIDQ3LjA1OSwzMC40MTkgMjEuMjczLDU2LjEwNyAyMS4yNzMsNzIuMDE4IDQ2LjUwOCw0Ny4wODEgNjAuNTM3LDY0LjIyOSAgICA5NC4xNzMsMjYuMzM4ICAi"+
								"PjwvcG9seWdvbj48L2c+PC9zdmc+"} />
							</Button></td>
								)}
							<td className="table-borderless"></td>	
						</tr>	
					</tbody>
				</Table><div ref={this.refBottom}></div>
				<div className="student-record-chart">
					  {this.state.showChart && <HighchartsReact
						  highcharts={Highcharts}
						  options={this.state.chartOptions}
					  />}
					</div>

				{!!this.state.isAdmin && <Row className="class-delete-row">
					<Mutation mutation={DELETE_STUDENT}>
							{(deleteStudentMutate) => {
								this.onDeleteStudentMutate = (stid) => deleteStudentMutate( {variables: { stid } } )
								return (
									null
								)
							}
							}
						</Mutation>
					<Modal show={this.state.deleteStudentShow} onHide={this.handleDeleteClose} animation={false} centered>
							<Modal.Header closeButton>
								<Modal.Title>Confirm Delete</Modal.Title>
							</Modal.Header>
							<Modal.Body>Are you sure you want to permanently delete {this.state.englishName}?
							<p>Type <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>delete</span> to confirm</p></Modal.Body>
							
								<Form.Control
									className="class-delete-input"
									aria-label="delete student"
									onChange={this.deleteTextChange}
									value={this.state.deleteInput}
								/>
							<Modal.Footer>
							<Button variant="secondary" onClick={this.handleDeleteClose}>Cancel</Button>
							<Button variant="danger" onClick={this.deleteStudent} disabled={this.state.deleteInput!='delete'}>Delete</Button>
							</Modal.Footer>
						</Modal>
						<Col className="d-flex justify-content-end">
							<Button variant="danger" onClick={this.handleDeleteShow}>Delete Student</Button>
						</Col>
				</Row>}
				</>
		
		}
		return (
			<div className="student">
				{studentBlock}
			</div>
		);		
		
		
	}
}


class InternalNote extends React.Component {
	constructor(props) {
		super(props);
		this.noteId = this.props.noteId;
		const pd = new Date(parseInt(this.props.pdate));
		const pdate = new Date(pd.getTime() - (pd.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		this.state = {confirmDelete:false, editing:false, noteEn: this.props.noteEn, noteJp: this.props.noteJp, author:this.props.author, pdate:pdate, pd:pd,
			translateLoading:false};
		this.confirmDeleteShow = this.confirmDeleteShow.bind(this);
		this.confirmDeleteHide = this.confirmDeleteHide.bind(this);
		
		this.editNote = this.editNote.bind(this);
		this.confirmEditNote = this.confirmEditNote.bind(this);
		this.handleNoteEnChange = this.handleNoteEnChange.bind(this);
		this.handleNoteJpChange = this.handleNoteJpChange.bind(this);
		
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleNoteAuthorChange = this.handleNoteAuthorChange.bind(this);

		this.translateToJp = this.translateToJp.bind(this);
	}
	
	editNote(){
		this.setState({editing: true});
	}
	
	handleNoteEnChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({noteEn: value});
	}
	handleNoteJpChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({noteJp: value});
	}
	handleDateChange(date){
		this.setState({pd: date});
	}
	handleNoteAuthorChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({author: value});
	}
	
	async confirmEditNote(){
		const { data } = await this.onEditRecordMutate(parseInt(this.noteId), parseInt(this.props.studentId), this.state.noteEn, this.state.noteJp, this.state.author, new Date(this.state.pd.getTime() - (this.state.pd.getTimezoneOffset() * 60000)).toISOString());
		const pdate = new Date(this.state.pd.getTime() - (this.state.pd.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		this.props.editFunc(data.editRecordNote);
		this.setState({editing: false, pdate:pdate});
	}

	confirmDeleteShow() {
		this.setState({confirmDelete:true});
	}
	confirmDeleteHide() {
		this.setState({confirmDelete:false});
	}

	async translateToJp() {
		this.setState({translateLoading: true});
		
		let tempComment = this.state.noteEn;

		this.props.translations.forEach((translation) => {
			
			const regex = new RegExp('\\b' + translation.original + '\\b', "g");
			tempComment = tempComment.replaceAll(regex, "<Q"+translation.original.replace(/ /g,'')+"Q>");	
		});
		
		let jp = await translate(tempComment, "ja");
		
		this.props.translations.forEach((translation) => {
			jp = jp.replaceAll("<Q"+translation.original.replace(/ /g,'')+"Q>", translation.replacement);
		});
		
		this.setState({noteJp: jp});
		this.setState({translateLoading: false});
		
	}
	
	render() {
		let deleteButton = <div className="student-note-delete">
							<Button variant="primary" size="sm" onClick={this.editNote}>Edit Note</Button>
							<Button variant="danger" size="sm" onClick={this.confirmDeleteShow}>Delete Note</Button>
							</div>
		if (!!this.state.confirmDelete) {
			deleteButton = <div className="student-note-delete">
								<Button variant="danger" size="sm" onClick={this.props.removeFunc.bind(this,this.noteId)}>Confirm Delete</Button>
								<Button variant="secondary" size="sm" onClick={this.confirmDeleteHide}>Cancel</Button>
							</div>
		}
		
		let notes = <><div className="student-note-text">{this.state.noteEn}</div>
					<div className="student-note-text">{this.state.noteJp}</div></>;
					
		let footer = <><div className="student-note-date">{this.state.pdate}</div>
					<div>{this.state.author}</div></>;
					
		if (!!this.state.editing) {
			notes = <><div className="student-note-text"><TextareaAutosize className="student-external-input" type="text" value={this.state.noteEn} onChange={this.handleNoteEnChange}/>
						{!this.state.translateLoading && <div className="grade-comment-info" onClick={this.translateToJp}>T</div>}</div>

					<div className="student-note-text"><TextareaAutosize className="student-external-input" type="text" value={this.state.noteJp} onChange={this.handleNoteJpChange}/></div></>;
			
			footer = <><div className="student-note-date"><DatePicker className="new-note-date" selected={this.state.pd} onChange={(date) => this.handleDateChange(date)} dateFormat="yyyy-MM-dd"/></div>
					<div><input className="student-external-input" type="text" value={this.state.author} onChange={this.handleNoteAuthorChange}/></div></>;
			deleteButton = <Button className="student-note-delete" variant="primary" size="sm" onClick={this.confirmEditNote}>Confirm</Button>
		}
		
		return (
			<div className="student-note">
				<Mutation mutation={EDIT_RECORD_NOTE}>
					{(editRecordMutate) => {
						this.onEditRecordMutate = (nid, stid, nen, njp, author, pdate) => editRecordMutate( {variables: { nid, stid, nen, njp, author, pdate } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<div className="student-note-text-wrapper">
					{notes}
				</div>
				<div className="student-note-footer">
					{footer}
					{deleteButton}
				</div>
				
			</div>
		);
	}
}	



class ExternalLink extends React.Component {
	constructor(props) {
		super(props);
		this.externalId = this.props.externalId;
		this.state = {confirmDelete:false};
		this.confirmDeleteShow = this.confirmDeleteShow.bind(this);
		this.confirmDeleteHide = this.confirmDeleteHide.bind(this);
	}
	
	confirmDeleteShow() {
		this.setState({confirmDelete:true});
	}
	confirmDeleteHide() {
		this.setState({confirmDelete:false});
	}
	
	render() {
		let deleteButton = <Button className="student-note-delete" variant="danger" size="sm" onClick={this.confirmDeleteShow}>Delete Link</Button>
		if (!!this.state.confirmDelete) {
			deleteButton = <div className="student-note-delete">
								<Button variant="danger" size="sm" onClick={this.props.removeFunc.bind(this,this.externalId)}>Confirm Delete</Button>
								<Button variant="secondary" size="sm" onClick={this.confirmDeleteHide}>Cancel</Button>
							</div>
		}
		
		return (
			<div className="student-note">
				
				{(this.props.description!="") &&
					<div className="student-external-info">{this.props.description}</div>
					}
				<div className="student-external-info student-external-link" ><a style={{display: "table-cell"}} href={this.props.link} target="_blank">{this.props.link}</a></div>
				
				
				<div className="student-external-delete">
					{deleteButton}
				</div>
				
			</div>
		);
	}
	
}



class CourseRow extends React.Component {
	constructor(props) {
		super(props);
		let gradesDict = {}
		this.props.grades.forEach(grade => {
			gradesDict[grade.subcategory_id] = grade.grade_level;
			});

		let avgDict = {}
		let maxDict = {}
		if (this.props.averages) {
			this.props.averages.forEach(average => {
				avgDict[average.subcategory_id] = average.grade_level;
				maxDict[average.subcategory_id] = average.subcategory_max;
				});	
		}
		this.props.subcategories.forEach(subcat => {
			if (!avgDict[subcat.subcategory_id]) {
				avgDict[subcat.subcategory_id] = 0;
			}
			if (!maxDict[subcat.subcategory_id]) {
				maxDict[subcat.subcategory_id] = 0;
			}
		});		

		let commentEnState = "";
		if (this.props.commentEn) {
			commentEnState = this.props.commentEn;
		}
		let commentJpState = "";
		if (this.props.commentJp) {
			commentJpState = this.props.commentJp;
		}
		this.state = {grades:gradesDict, commentEn:commentEnState, commentJp:commentJpState, modalShow:false, active:false, averages:avgDict, maxes: maxDict, generateLoading:false};
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);		

		this.activatePrevious = this.activatePrevious.bind(this);  
		this.deactivatePrevious = this.deactivatePrevious.bind(this);

		this.generateRep = this.generateRep.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentActiveTerm != prevProps.currentActiveTerm) {
			if (this.props.currentActiveTerm != this.props.termId) {
				this.setState({active: false});
			}
		}
		if (this.props.averages != prevProps.averages) {
			let avgDict = {}
			let maxDict = {}
			if (this.props.averages) {
				this.props.averages.forEach(average => {
					avgDict[average.subcategory_id] = average.grade_level;
					maxDict[average.subcategory_id] = average.subcategory_max;
					});	
			}
			this.props.subcategories.forEach(subcat => {
				if (!avgDict[subcat.subcategory_id]) {
					avgDict[subcat.subcategory_id] = 0;
				}
				if (!maxDict[subcat.subcategory_id]) {
					maxDict[subcat.subcategory_id] = 0;
				}
			});	
			this.setState({averages:avgDict});
		}
	}
	activatePrevious(){
		this.props.activeFunc(this.props.termId);
		this.setState({active: true});
	}
	deactivatePrevious(){
		this.setState({active: false});
	}

	handleClose() {
		this.setState({modalShow: false});
	}
	handleShow(){
		this.setState({modalShow: true});
	}

	async generateRep() {
		this.setState({generateLoading: true});
		const repo = await this.props.generateFunc(this.props.studentId,this.props.classId);
		this.setState({generateLoading: false});
	}

	render() {
		let termNum;
		
		if (this.props.termNumber) {
			termNum = <span>Term {this.props.termNumber}, {this.props.termYear}</span>;
		}

		let generateButton;
		if (this.props.termActive==false) {
			if (this.state.generateLoading==true) {
				generateButton = <Spinner animation="border" variant="primary" />;
			} else generateButton = <Button variant="outline-primary" onClick={this.generateRep}>Report</Button>;
		}

		let classAvg;
		if (this.state.active==true) {
			classAvg = <tr className="class-student-row class-row-previous">
				<td className="table-borderless">{generateButton}</td>
				<td className="table-borderless"></td>
				<td  className="course-names" onClick={this.deactivatePrevious}>Class Average</td>
				{this.props.subcategories.map((subcat) =>
				<td key={"AVG"+subcat.subcategory_id} className="class-average"><span className="class-avg-over">{this.state.averages[subcat.subcategory_id]}</span>
						<span className="class-avg-div"></span>
					<span className="class-avg-under">{this.state.maxes[subcat.subcategory_id]}</span></td>
					)}
				<td></td>
			</tr>
			
		}
		return (
			<>
			<tr className="class-student-row" onClick={this.activatePrevious}>
				<td className="records-term sticky-name">{termNum}</td>
				<td className="records-term student-class-button">
					{this.props.class_name && <LinkContainer to={"/class/"+this.props.classId}>
						<Button>{this.props.class_name}</Button>
					</LinkContainer>}
				</td>
				<td className="records-term">{this.props.classTeacher}</td>
				{this.props.subcategories.map((subcat) =>
				<CourseGradeInput key={"SG"+subcat.subcategory_id+this.props.studentId} maxGrade={subcat.subcategory_max} colour={subcat.colour} editAvgFunc={this.props.editAvgFunc}
					gradeLvl={this.state.grades[subcat.subcategory_id]} termId={this.props.termId} subcategoryId={subcat.subcategory_id} gradeChange={this.props.gradeChanges[subcat.subcategory_id]}/>
					)}
				<td className="class-student-comment" onClick={this.handleShow}>
					<div className="class-student-comment-overflow"><span>{this.state.commentEn}</span></div>
				</td>

				<Modal show={this.state.modalShow} onHide={this.handleClose} animation={false} centered>
					<Modal.Header closeButton>
					<Modal.Title>Comments for Term {this.props.termNumber}, {this.props.termYear}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>{this.state.commentEn}</div>
						<div>{this.state.commentJp}</div>
					</Modal.Body>
				</Modal>
			</tr>
			{classAvg}
			</>
		);
	}
}



class CourseGradeInput extends React.Component {
	constructor(props) {
		super(props);
		let gradeLvl = "";
		if (this.props.gradeLvl) {
			gradeLvl = this.props.gradeLvl;
		}
		this.state = {gradeLvl:gradeLvl};
		this.gradeColour = this.gradeColour.bind(this);
	}

	gradeColour(change) {
		if (change>0) {
			return "2px solid blue";
		}
		if (change<0) {
			return "2px solid red";
		}
		return "0";
	}
	
	render() {

		
		return (
			 <td className="class-input-grade" style={{backgroundColor:this.props.colour}}>
				<div className="record-grade"  style={{border:this.gradeColour(this.props.gradeChange)}}>
					<div>{this.state.gradeLvl}</div>
				</div>
			  </td>
		);
	}
}

export default withApollo(graphql(GET_STUDENT, {
  options: props => ({
	  variables: {
		  stid: parseInt(props.match.params.id)
		},
	  fetchPolicy: 'network-only',
	 }),
})(StudentRecord));