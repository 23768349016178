import React from 'react'

import Report from './report';
import ReportAdmin from './reportadmin';
import { Auth } from 'aws-amplify';

class ReportSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {id:false,isAdmin:false}
	}

	async componentDidMount() {

		const idTokenPayload = (await Auth.currentSession()).getIdToken().payload;
		//console.log(idTokenPayload);
		if (idTokenPayload["cognito:groups"]) {
			if (idTokenPayload["cognito:groups"].includes("fujiadmin")) {
				this.setState({isAdmin:true});
			}
		}
		this.setState({id:true});
	}
	
  render() {

		let report;
		//console.log(this.state.isAdmin);
		if (this.state.id==true) {
			if (this.state.isAdmin==true) {
				report = <ReportAdmin />
			} else {
				report = <Report />;
			}
		}	
		return (
			<>{report}</>
		);
	}
}

export default ReportSelect;