import React from 'react'
import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './header';
import ReportCategory from './reportcategory';

import { gql } from 'apollo-boost';
import { graphql } from '@apollo/client/react/hoc';
import { Mutation, Query } from '@apollo/client/react/components';
import Spinner from 'react-bootstrap/Spinner';


const TITLE = "Fuji - Report";


const GET_CATEGORIES = gql`
	query reportCategories {
	  reportCategories {
		 category_id
		 category_en
		 category_jp
		 category_max
		 colour
		 subcategories {
			subcategory_id
			subcategory_en
			subcategory_jp
			subcategory_max
			subcategory_unit
			grades {
				grade_id
				grade_level
				grade_en
				grade_jp
			}
		 }
	  }
	}
`;


class Report extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {categories: [], loading:true};
	}
	
	
	
	async componentDidMount() {
		this.mounted = true;
		while(this.mounted && !this.props.data.hasOwnProperty("reportCategories"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			this.setState({loading: false});
			this.setState({categories: this.props.data.reportCategories});
			document.title = "Fuji - Report";
		}
	}
	
	async componentWillUnmount() {
		this.mounted = false;
	}
	
	render() {
		
	  
	  return (
		<div className="report">
			<Container fluid>
				{!!this.state.loading && <Row className="d-flex justify-content-center"><Spinner animation="border" variant="primary" /></Row>}
				
				
				{this.state.categories.map((cat) =>
					<ReportCategory key={"C"+cat.category_id} categoryId={cat.category_id} categoryEn={cat.category_en} categoryJp={cat.category_jp} categoryMax={cat.category_max}
						colour={cat.colour}	removeFunc={this.removeCategory} subcategories={cat.subcategories}/>
				)}
				
				
			</Container>
		</div>
	  );
    }
}


export default graphql(GET_CATEGORIES, {
  options: { fetchPolicy: 'network-only' },
})(Report);