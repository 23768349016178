import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { Redirect } from 'react-router-dom'

import { Auth } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {signedIn: true, isAdmin:false};
		this.signOut = this.signOut.bind(this);
		

	}

	async componentDidMount() {
		const idTokenPayload = (await Auth.currentSession()).getIdToken().payload;
		if (idTokenPayload["cognito:groups"]) {
			if (idTokenPayload["cognito:groups"].includes("fujiadmin")) {
				this.setState({isAdmin:true});
			}
		}

	}

	async signOut() {
		try {
			await Auth.signOut();
			window.location.href = "./";
		} catch (error) {
			console.log('error signing out: ', error);
		}
		
	}
	
	render() {
		let adminLink;
		let adminStyle;
		if (this.state.isAdmin==true) {
			adminStyle = {marginRight:"88px"};
			adminLink = <LinkContainer to="/admin"><Nav.Link className="navbar-signout">Admin</Nav.Link></LinkContainer>
		}
		
		if (this.state.signedIn) {
			return (
		
				<Navbar bg="light" expand="md" className="ml-auto">
				
					<LinkContainer to="/" style={adminStyle}>
						<Navbar.Brand><img className="navbar-logo" src="/Fuji.png" alt="logo"/></Navbar.Brand>
					</LinkContainer>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className="">
						<Nav className="mx-auto">
								<LinkContainer to="/active">
									<Nav.Link>Active Classes</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/archive">
									<Nav.Link>Archives</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/student">
									<Nav.Link>Students</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/report">
									<Nav.Link>Report</Nav.Link>
								</LinkContainer>
						</Nav>
						<Nav>

							{adminLink}
							
							<Nav.Link onClick={this.signOut} className="navbar-signout">Sign out</Nav.Link>
							
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			)
		} else {
			return (
			<Redirect to="/" />
				);
			
		}
		
	
	}
	
}

export default Header;