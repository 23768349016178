import React from 'react'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { gql } from 'apollo-boost';
import { Mutation, Query } from '@apollo/client/react/components';
import ReportSubcategory from './reportsubcategoryadmin';

import { ChromePicker } from 'react-color';

const NEW_SUBCATEGORY = gql`
	mutation newReportSubcat($cid: Int) {
		newReportSubcategory(cid: $cid) {
			subcategory_id
			subcategory_en
			subcategory_jp
			subcategory_max
			subcategory_unit
			grades {
				grade_id
				grade_level
				grade_en
				grade_jp
			}
		 }		
	}
`;


const EDIT_CATEGORY_EN = gql`
	mutation editReportCategoryEn($cid: Int, $text: String) {
	  editReportCategoryEn(cid: $cid, text: $text)
	}
`;

const EDIT_CATEGORY_JP = gql`
	mutation editReportCategoryJp($cid: Int, $text: String) {
	  editReportCategoryJp(cid: $cid, text: $text)
	}
`;

const EDIT_CATEGORY_COLOUR = gql`
	mutation editReportCategoryColour($cid: Int, $text: String) {
	  editReportCategoryColour(cid: $cid, text: $text)
	}
`;

const DEL_SUBCATEGORY = gql`
	mutation deleteReportSubategory($scid: Int) {
	  deleteReportSubategory(scid: $scid)
	}
`;



class ReportCategory extends React.Component {
	constructor(props) {
		super(props);
		let defaultcolour;
		if (this.props.colour=="") {
			defaultcolour="#C9DAF8";
		} else {
			defaultcolour=this.props.colour;
		}

		this.state = {subcategories: this.props.subcategories,
						modalShow: false,
						lang: "en",
						maxGrade: this.props.categoryMax,
						categoryEn: this.props.categoryEn,
						categoryJp: this.props.categoryJp,
						colour: defaultcolour,
			};
		this.categoryId = this.props.categoryId;
		this.newSubcategory = this.newSubcategory.bind(this);  
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleCatEnInputChange = this.handleCatEnInputChange.bind(this);
		this.handleCatJpInputChange = this.handleCatJpInputChange.bind(this);
		this.handleColourChange = this.handleColourChange.bind(this);
		this.handleColourChangeComplete = this.handleColourChangeComplete.bind(this);
		this.typingTimerEn = null;
		this.typingTimerJp = null;
		this.removeSubcategory = this.removeSubcategory.bind(this);
		this.editMaxGrade = this.editMaxGrade.bind(this);
		this.maxGradeDict = {};
	}
	
	async componentWillUnmount() {
		if (this.typingTimerEn) {
			this.onEditCatEnMutate( parseInt(this.categoryId), this.state.categoryEn );
			clearTimeout(this.typingTimerEn);
		}
		if (this.typingTimerJp) {
			this.onEditCatJpMutate( parseInt(this.categoryId), this.state.categoryJp );
			clearTimeout(this.typingTimerJp);
		}
	}
	
	newSubcategory(data) {
		this.setState({subcategories: [...this.state.subcategories, data.newReportSubcategory]});
	}
	
	removeSubcategory(subcategory_id) {
		this.onDeleteSCMutate( parseInt(subcategory_id) );
		const subcatList = this.state.subcategories;
		const index = subcatList.findIndex(x => x.subcategory_id == subcategory_id);
		if (index > -1) {
			subcatList.splice(index, 1);
			this.setState({subcategories: subcatList});
		}
		this.editMaxGrade(subcategory_id,0);
		
	}
	
	handleClose() {
		this.setState({modalShow: false});
	}
	handleShow(){
		this.setState({modalShow: true});
	}
	
	editCategoryEn(category_id, text) {
		this.onEditCatEnMutate( category_id, text );
	}
	
	handleCatEnInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
		  [name]: value    
		});
		clearTimeout(this.typingTimerEn);
		this.typingTimerEn = setTimeout(() => {
			this.onEditCatEnMutate( parseInt(this.categoryId), value );
			this.typingTimerEn = null;
			}, 1000);
	}
	
	handleCatJpInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
		  [name]: value    
		});
		clearTimeout(this.typingTimerJp);
		this.typingTimerJp = setTimeout(() => {
			this.onEditCatJpMutate( parseInt(this.categoryId), value );
			this.typingTimerJp = null;
			}, 1000);
	}
	
	handleColourChange = (color) => {
		this.setState({ colour: color.hex });
	  };
	  
	handleColourChangeComplete = (color) => {
		this.onEditCatColourMutate( parseInt(this.categoryId), color.hex )
	}; 
	
	changeLang(event) {
		const target = event.target;
		const value = target.value;
	}
	
	editMaxGrade(subcatId,gradeLvl) {
		this.maxGradeDict[subcatId] = gradeLvl;
		const result = Object.entries(this.maxGradeDict).reduce((a, b) => a[1] > b[1] ? a : b)[1];
		if (result<10) {
			this.setState({maxGrade: result+1});
		} else {
			this.setState({maxGrade: 10});
		}
		
	}

	render() {
		const langEn = ((this.state.lang!="en") ? " inactive-lang" : "");
		const langJp = ((this.state.lang!="jp") ? " inactive-lang" : "");
		
		const gradeLevels = [];
		for (let i = 1; i < this.state.maxGrade+1; i++) {
			gradeLevels.push(<th key={"GH"+i} className="report-grade">{i}</th>);
		}
		
		return (
			<Row className="report-category">
				<div className="report-category-heading" style={{ backgroundColor: this.state.colour}}>
					<Mutation mutation={EDIT_CATEGORY_EN}>
						{(editCatEnMutate) => {
							this.onEditCatEnMutate = (cid, text) => editCatEnMutate( {variables: { cid, text } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<Mutation mutation={EDIT_CATEGORY_JP}>
						{(editCatJpMutate) => {
							this.onEditCatJpMutate = (cid, text) => editCatJpMutate( {variables: { cid, text } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<Mutation mutation={EDIT_CATEGORY_COLOUR}>
						{(editCatColourMutate) => {
							this.onEditCatColourMutate = (cid, text) => editCatColourMutate( {variables: { cid, text } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					
					<h2>
						<input type="text" name="categoryEn" placeholder="Category Name" className={"report-category-heading-input" + langEn}
									value={this.state.categoryEn} onChange={this.handleCatEnInputChange}/>
						<input type="text" name="categoryJp" placeholder="Category Name (JP)" className={"report-category-heading-input" + langJp} value={this.state.categoryJp} onChange={this.handleCatJpInputChange}/>
						<Button className="report-delete-category" variant="danger" onClick={this.handleShow}>
							Delete Category
						</Button>
						<ChromePicker
							color={ this.state.colour }
							onChange={ this.handleColourChange }
							onChangeComplete={ this.handleColourChangeComplete }
						  />
					</h2>
					<Modal show={this.state.modalShow} onHide={this.handleClose} animation={false} centered>
						<Modal.Header>
						  <Modal.Title>Confirm delete</Modal.Title>
						</Modal.Header>
						<Modal.Body>Are you sure you want to delete {(this.state.categoryEn) ? this.state.categoryEn : " this"}?</Modal.Body>
						<Modal.Footer>
						  <Button variant="secondary" onClick={this.handleClose}>
							Cancel
						  </Button>
						  <Button variant="danger" onClick={this.props.removeFunc.bind(this,this.categoryId)}>
							Delete
						  </Button>
						</Modal.Footer>
					  </Modal>
				</div>
			
				
				<Table striped hover responsive size="sm">
					<thead>
						<tr>
							<th className="">
								
								<ButtonGroup className="report-language-toggle" toggle>
									<ToggleButton
										key="en"
										type="radio"
										variant="primary"
										name="radio"
										value={"en"}
										checked={this.state.lang === "en"}
										onChange={(e) => this.setState({lang: e.currentTarget.value})}
									  >EN</ToggleButton>
									<ToggleButton
										key="jp"
										type="radio"
										variant="primary"
										name="radio"
										value={"jp"}
										checked={this.state.lang === "jp"}
										onChange={(e) => this.setState({lang: e.currentTarget.value})}
									  >JP</ToggleButton>
									
								</ButtonGroup>
							</th>
							<th className="report-subcategory-level"></th>
							
							{gradeLevels}
						</tr>
					</thead>
					<tbody className={"report-catmax-"+this.state.maxGrade}>
					<Mutation mutation={DEL_SUBCATEGORY}>
						{(deleteSCMutate) => {
							this.onDeleteSCMutate = (scid) => deleteSCMutate( {variables: { scid } } )
							return (
								null
							)
						}
					 }
					</Mutation>
						{this.state.subcategories.map((subcat) =>
							<ReportSubcategory key={"SC"+subcat.subcategory_id} subcategoryId={subcat.subcategory_id}  maxGradeFunc={this.editMaxGrade} subcategoryMaxGrade={subcat.subcategory_max}
							subcategoryEn={subcat.subcategory_en} subcategoryJp={subcat.subcategory_jp} lang={this.state.lang} removeSCFunc={this.removeSubcategory} categoryId={this.categoryId}
							subcategoryUnit={subcat.subcategory_unit} grades={subcat.grades}/>
						)}
				  </tbody>
				  
				</Table>
				<div>
				<Mutation mutation={NEW_SUBCATEGORY} onCompleted={this.newSubcategory} >
					{(onMutate) => {
						const onMutateFunc = () => {
							onMutate( {variables: { cid: parseInt(this.categoryId) } } );								
						};
						return (
							<Button variant="success" onClick={onMutateFunc} className="report-new-subcategory-button">New Subcategory</Button>
							
						)
					 }
					}
				</Mutation>
				
				</div>
			</Row>
		);		
		
		
	}
}




export default ReportCategory;
