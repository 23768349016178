import React from 'react'
import logo from './logo.svg'

import ApolloClient, { gql } from 'apollo-boost'
import { ApolloProvider, useQuery} from '@apollo/react-hooks';
import config from './aws-exports'
import Amplify, { Auth, Storage } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/home';
import P404 from './components/404';
import Active from './components/active';
import Course from './components/course';
import Archive from './components/archive';
import Student from './components/student';
import ReportSelect from './components/reportselect';
import Admin from './components/admin';
import './style/App.scss';
import Header from './components/header';
import { Query } from '@apollo/client/react/components';
import translate from "translate";

/*
* The endpoint is created by the Amplify CLI and stored in the aws-exports.js file
*/
const { endpoint } = config.aws_cloud_logic_custom[0]


translate.engine = "deepl";
translate.key = process.env.REACT_APP_DEEPL_API_KEY;

const GET_TERM = gql`
	query getCurrentTerm {
		getCurrentTerm {
			term_id
			term_number
			term_year
		}
  	}
`;

Amplify.configure(config);


/* Create client using the GraphQL endpoint  */
const client = new ApolloClient({
  uri: endpoint + '/graphql'
});

const AppWithProvider = () => (
  <ApolloProvider client={client}>
     <Router>
	 <Query query={GET_TERM} fetchPolicy='network-only'>
			{({ loading, error, data }) => {
				return null
			}}
	</Query>

     <Header />
		  <Switch>
			<Route exact path="/" component={Home} />
			<Route path="/active" component={Active} />
			<Route path="/archive" component={Archive} />
			<Route path="/student" component={Student} />
			<Route path="/class/:id" component={Course} />
			<Route path="/report" component={ReportSelect} />
			<Route path="/admin" component={Admin} />
			<Route path="/" component={P404} />
		  </Switch>
		</Router>
  </ApolloProvider>
);

export default withAuthenticator(AppWithProvider)

