import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { gql } from 'apollo-boost';
import { Mutation } from '@apollo/client/react/components';

import TextareaAutosize from 'react-textarea-autosize';


class ReportGrade extends React.Component {
	constructor(props) {
		super(props);
		this.state = {gradeEn: this.props.gradeEn, gradeJp: this.props.gradeJp, gradeLvl: this.props.gradeLvl};
		this.gradeId = this.props.gradeId;
	}
	
	

	render() {
		const langEn = ((this.props.lang!="en") ? "inactive-lang" : "");
		const langJp = ((this.props.lang!="jp") ? "inactive-lang" : "");
	
		
		return (
			<td className="report-grade">
				
				<div className={langEn}>{this.state.gradeEn}</div>
				<div className={langJp}>{this.state.gradeJp}</div>
			
				
					  
			</td>
		);		
		
		
	}
}



export default ReportGrade;
