import React from 'react'
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {LinkContainer} from 'react-router-bootstrap'
import Header from './header';
import { gql } from 'apollo-boost';
import { graphql } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import { Auth } from 'aws-amplify';
import Spinner from 'react-bootstrap/Spinner';


const GET_ACTIVE_CLASSES = gql`
	query getActiveClasses {
		getActiveClasses {
			class_list {
				class_id
				class_name
				class_teacher
				class_unit
			}
			term_year
			term_number
		}
	}
`;

const NEW_CLASS = gql`
	mutation createClass($name: String, $teacher: String) {
		createClass(name: $name, teacher: $teacher){
			class_id
			class_name
			class_teacher
		}	
		
	}
`;

class ActiveList extends React.Component {
	render() {
		return (
			<Container fluid="true">
				

			</Container>
			
		);
	}
}

class Active extends React.Component {
	constructor(props) {
		super(props);
		this.state = {classes:[],newClassShow:false,newClassName:"",newTeacher:"",term_year:0,term_number:0, loading:true};
		this.showNewClassCard = this.showNewClassCard.bind(this);
		this.hideNewClassCard = this.hideNewClassCard.bind(this);
		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeTeacher = this.handleChangeTeacher.bind(this);
		this.createNewClass = this.createNewClass.bind(this);
		this.getClassColour = this.getClassColour.bind(this);
	}
	
	
	async componentDidMount() {
		this.mounted = true;
		while(this.mounted && !this.props.data.hasOwnProperty("getActiveClasses"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			this.setState({loading: false});
			this.setState({classes: this.props.data.getActiveClasses.class_list});
			this.setState({term_year: this.props.data.getActiveClasses.term_year});
			this.setState({term_number: this.props.data.getActiveClasses.term_number});
			document.title = "Fuji - Active Classes";
			/*
			const userdets = await Auth.currentUserInfo();
			console.log("dets");
        	console.log(userdets);
			const userpool = await Auth.currentUserPoolUser();
			console.log("pool");
        	console.log(userpool);
			*/
		}
	}
	
	showNewClassCard() {
		this.setState({newClassShow: true});
	}
	hideNewClassCard() {
		this.setState({newClassShow: false});
	}
	handleChangeName(event){
		const target = event.target;
		const value = target.value;
		this.setState({newClassName: value});
	}
	handleChangeTeacher(event){
		const target = event.target;
		const value = target.value;
		this.setState({newTeacher: value});
	}
	async createNewClass(){
		const { data } = await this.onNewClassMutate(this.state.newClassName,this.state.newTeacher);
		this.setState({newTeacher: "", newClassName: ""});
		if (data.createClass) {
			this.setState({classes: [...this.state.classes, data.createClass]});
		}
	}

	getClassColour(unit){
		if (unit>0 && unit<11) return "class-unit-blue";
		else if (unit>10 && unit<21) return "class-unit-green";
		else if (unit>20 && unit<31) return "class-unit-yellow";
		else if (unit>30) return "class-unit-red";
		else return "class-unit-purple";
	}
	
  render() {
	  let newClassCard;
	  if (this.state.newClassShow==true) {
		  newClassCard = <Card style={{ width: '18rem' }} className="classlist-new-card">
			<div className="d-flex justify-content-end"><Button variant="secondary" onClick={this.hideNewClassCard}>Cancel</Button></div>
				<Mutation mutation={NEW_CLASS}>
					{(newClassMutate) => {
						this.onNewClassMutate = (name, teacher) => newClassMutate( {variables: { name, teacher } } )
						return (
							null
						)
					}
				 }
				</Mutation>	
				<InputGroup className="mb-3">
					<InputGroup.Prepend>
					  <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control
					  placeholder="Class name"
					  aria-label="Class name"
					  aria-describedby="basic-addon1"
					onChange={this.handleChangeName}
					value={this.state.newClassName}/>
				  </InputGroup>
				  
				  <InputGroup className="mb-3">
					<InputGroup.Prepend>
					  <InputGroup.Text id="basic-addon2">Teacher</InputGroup.Text>
					</InputGroup.Prepend>
					<Form.Control
					  placeholder="Teacher"
					  aria-label="Teacher"
					  aria-describedby="basic-addon2"
					onChange={this.handleChangeTeacher}
					value={this.state.newTeacher}/>
				  </InputGroup>
				  <Button variant="success" onClick={this.createNewClass} disabled={!(this.state.newClassName.length>0)}>Create</Button>
					  
			</Card>
			
	  } else if (this.state.term_number !=0) {
		  newClassCard=<Button variant="success" size="sm" className="class-link-button" onClick={this.showNewClassCard}>New Class</Button>;
	  }
		
	

	  let h1title;
	  if (this.state.term_number !=0) {
		h1title = <h1>Term {this.state.term_number}, {this.state.term_year} Classes</h1>;
	  }
	  return (
		<div className="active">

		
			<Container fluid="true">
				<div className="classlist-title">
					{h1title}
				</div>
				{!!this.state.loading && <div className="d-flex justify-content-center w-100"><Spinner animation="border" variant="primary" /></div>}
				<div className="classlist w-100">
					<Col className="d-flex justify-content-center">
					<ListGroup horizontal>
						{this.state.classes.map((course) =>
								<LinkContainer to={"/class/"+course.class_id} key={"LC"+course.class_id}>
									<ListGroup.Item action className={"classlist-list-item "+this.getClassColour(course.class_unit)}>
										<div className="classlist-class-name">{course.class_name}</div>
										<div className="classlist-class-bottom">
											<div className="classlist-teacher">{course.class_teacher}</div>
											<div className="classlist-class-unit">{!!course.class_unit && 'U'}{course.class_unit}</div>
										</div>
									</ListGroup.Item>
								</LinkContainer>
							)}
					  
					</ListGroup>
					</Col>
				</div>

				<div>
				<Col className="d-flex justify-content-center">
					{newClassCard}
					
				</Col>	
				</div>
			
			</Container>
		  
		</div>
	  );
  }
}

export default graphql(GET_ACTIVE_CLASSES, {
  options: { fetchPolicy: 'network-only' },
})(Active);