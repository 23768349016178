import React from 'react'
import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './header';
import ReportCategory from './reportcategoryadmin';

import { gql } from 'apollo-boost';
import { graphql } from '@apollo/client/react/hoc';
import { Mutation, Query } from '@apollo/client/react/components';
import Spinner from 'react-bootstrap/Spinner';


const TITLE = "Fuji - Report";


const NEW_CATEGORY = gql`
	mutation newReportCat {
		newReportCategory{
			category_id
			category_en
			category_jp
			category_max
			colour
		 subcategories {
			subcategory_id
			subcategory_en
			subcategory_jp
			subcategory_max
			subcategory_unit
			grades {
				grade_id
				grade_level
				grade_en
				grade_jp
			}
		 }			
		}
	}
`;

const GET_CATEGORIES = gql`
	query reportCategories {
	  reportCategories {
		 category_id
		 category_en
		 category_jp
		 category_max
		 colour
		 subcategories {
			subcategory_id
			subcategory_en
			subcategory_jp
			subcategory_max
			subcategory_unit
			grades {
				grade_id
				grade_level
				grade_en
				grade_jp
			}
		 }
	  }
	}
`;

const DEL_CATEGORY = gql`
	mutation deleteReportCategory($cid: Int) {
	  deleteReportCategory(cid: $cid)
	}
`;



class ReportAdmin extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {categories: [], loading:true};
		this.newCategory = this.newCategory.bind(this);
		this.removeCategory = this.removeCategory.bind(this);
	}
	
	
	
	newCategory(data) {
		this.setState({categories: [...this.state.categories, data.newReportCategory]});
	}
	
	removeCategory(category_id) {
		this.onDeleteMutate( parseInt(category_id) );
		const catList = this.state.categories;
		const index = catList.findIndex(x => x.category_id == category_id);
		if (index > -1) {
			catList.splice(index, 1);
			this.setState({categories: catList});
		}
		
	}
	
	
	async componentDidMount() {
		this.mounted = true;
		while(this.mounted && !this.props.data.hasOwnProperty("reportCategories"))
			await new Promise(resolve => setTimeout(resolve, 100));
			
		if (this.mounted) {
			this.setState({loading: false});
			this.setState({categories: this.props.data.reportCategories});
			document.title = "Fuji - Report";
		}
	}
	
	async componentWillUnmount() {
		this.mounted = false;
	}
	
	render() {
		
	  
	  return (
		<div className="report">
			<Container fluid>
				{!!this.state.loading && <Row className="d-flex justify-content-center"><Spinner animation="border" variant="primary" /></Row>}
				<Mutation mutation={DEL_CATEGORY}>
					{(deleteMutate) => {
						this.onDeleteMutate = (cid) => deleteMutate( {variables: { cid } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				
				{this.state.categories.map((cat) =>
					<ReportCategory key={"C"+cat.category_id} categoryId={cat.category_id} categoryEn={cat.category_en} categoryJp={cat.category_jp} categoryMax={cat.category_max}
						colour={cat.colour}	removeFunc={this.removeCategory} subcategories={cat.subcategories}/>
				)}
				
				{!this.state.loading && <Row className="justify-content-md-center report-new-category-button">
					<Col md="auto">
						<Mutation mutation={NEW_CATEGORY} onCompleted={this.newCategory}>
							{(onMutate) => {
								const onMutateFunc = () => {
									onMutate({ });								
								};
								return (
									<Button variant="success" size="lg" onClick={onMutateFunc}>New Category</Button>
									
								)
							 }
						 }
						</Mutation>
					</Col>
					
				</Row>}
				
			</Container>
		</div>
	  );
    }
}





export default graphql(GET_CATEGORIES, {
  options: { fetchPolicy: 'network-only' },
})(ReportAdmin);