import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Header from './header';
import Spinner from 'react-bootstrap/Spinner';
import { gql } from 'apollo-boost';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Mutation } from '@apollo/client/react/components';
import TextareaAutosize from 'react-textarea-autosize';
import Modal from 'react-bootstrap/Modal';
import StudentSearch from './studentsearch';
import CourseRow from './courserow';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import translate from "translate";
import { Auth } from 'aws-amplify';
import throttle from 'lodash.throttle';
import { homeURL } from './homeurl';
import Tooltip from 'react-bootstrap/Tooltip';

//include font for Japanese characters in the pdf
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
	MPLUS: {
		normal: homeURL+'/fonts/mplus/MPLUS1p-Regular.ttf',
		bold: homeURL+'/fonts/mplus/MPLUS1p-Bold.ttf',
		italics: homeURL+'/fonts/mplus/MPLUS1p-Regular.ttf',
		bolditalics: homeURL+'/fonts/mplus/MPLUS1p-Bold.ttf',
	  }
}
//Define tables settings for the pdf generator
pdfMake.tableLayouts = {
  gradesLayout: {
    hLineWidth: function (i, node) {
      if (i === 0 || i === node.table.body.length) {
        return 0.5;
      }
      return (i === node.table.headerRows) ? 0.5 : 0.5;
    },
    vLineWidth: function (i) {
      return 0.5;
    },
    hLineColor: function (i) {
      return i === 1 ? 'black' : 'black';
    },
    paddingLeft: function (i) {
      return i === 0 ? 8 : 8;
    },
    paddingRight: function (i, node) {
      return (i === node.table.widths.length - 1) ? 8 : 8;
    }
  },
  detailsLayout: {
    hLineWidth: function (i, node) {
        return 0.5;
    },
    vLineWidth: function (i) {
      return 0.5;
    },
    hLineColor: function (i) {
      return i === 1 ? 'black' : 'black';
    },
    paddingLeft: function (i) {
      return 8;
    },
    paddingRight: function (i, node) {
      return 8;
    },
	paddingBottom: function (i) {
      return 6;
    },
    paddingTop: function (i, node) {
      return 6;
    }
  },
  commentsLayout: {
    hLineWidth: function (i, node) {
      return 0.5;
    },
    vLineWidth: function (i) {
      return 0.5;
    },
    hLineColor: function (i) {
      return i === 1 ? 'black' : 'black';
    },
    paddingLeft: function (i) {
      return 8;
    },
    paddingRight: function (i, node) {
      return 8;
    },
	paddingBottom: function (i) {
      return 3;
    },
    paddingTop: function (i, node) {
      return 3;
    }
  },
  imageLayout: {
    hLineWidth: function (i, node) {
      return 0;
    },
    vLineWidth: function (i) {
      return 0;
    }
  },
  headingLayout: {
    paddingLeft: function (i) {
      return 2;
    },
    paddingRight: function (i) {
      return 2;
    },
	paddingBottom: function (i) {
		if (i===0) return 0;
      else return 2;
    },
	paddingTop: function (i) {
		if (i===1) return 0;
		else return 2;
    }
  },
  tableLayout: {
    paddingLeft: function (i) {
      return 2;
    },
    paddingRight: function (i) {
      return 2;
    },
	paddingBottom: function (i) {
		return 2;
    },
	paddingTop: function (i) {
		return 2;
    }
  }
};

const GET_CLASS = gql`
	query getClass($clid: Int) {
		getClass(clid: $clid) {
			class_id
			class_name
			class_comment_en
			class_comment_jp
			class_teacher
			class_unit
			term_year
			term_number
			term_active
			term_id
			student_list {
				exclude_avg
				details {
					student_id
					japanese_name
					english_name
				}
				grades {
					subcategory_id
					grade_level
				}
				comment_en
				comment_jp
				prev_grades {
					subcategory_id
					grade_level
				}
				prev_prev_grades {
					subcategory_id
					grade_level
				}
				prev_comment_en
				prev_comment_jp
			}
			subcategories{
				subcategory_id
				subcategory_en
				subcategory_max
				colour
			}
		}
		getTranslations{
			original
			replacement
		}
		getReportComments(clid: $clid){
			subcategory_id
			grades{
				grade_level
				grade_en
				grade_jp
			}
		}
		getTooltips {
			grade_level
			subcategory_id
			tooltip
		} 
	}
`;
const GET_ALL_AVERAGES = gql`
	query getAllAverages($clid: Int) {
		getAllAverages(clid: $clid){
			class_id
			class_name
			class_teacher
			class_unit
			averages{
				subcategory_id
				grade_level
				subcategory_max
			}
		}
	}
`;
const CLASS_REPORT = gql`
	query getClassReport($clid: Int) {
		getClassReport(clid: $clid) {
			class_name
			class_comment_en
			class_comment_jp
			class_teacher
			class_unit
			term_year
			term_number
			student_list {
				exclude_avg
				details {
					student_id
					japanese_name
					english_name
				}
				grades {
					subcategory_id
					grade_level
				}
				comment_en
				comment_jp
			}
			categories{
				category_id
				category_en
				category_jp
				colour
				subcategories{
					subcategory_id
					subcategory_en
					subcategory_jp
					subcategory_max
					grades{
						grade_level
						grade_jp
					}
				}
			}
		}
	}
`;

const STUDENT_TO_CLASS = gql`
	mutation studentJoinClass($stid: Int, $clid: Int, $tid: Int) {
		studentJoinClass(stid: $stid, clid: $clid, tid: $tid){
			exclude_avg
			details {
				student_id
				japanese_name
				english_name
			}
			grades {
				subcategory_id
				grade_level
			}
			prev_grades {
				subcategory_id
				grade_level
			}
			prev_prev_grades {
				subcategory_id
				grade_level
			}
			comment_en
			comment_jp
			prev_comment_en
			prev_comment_jp
		}	
		
	}
`;

const REMOVE_STUDENT = gql`
	mutation removeStudent($stid: Int, $clid: Int) {
	  removeStudent(stid: $stid, clid: $clid)
	}
`;
const EDIT_CLASS_COMMENT_EN = gql`
	mutation editClassCommentEn($clid: Int, $cen: String) {
	  editClassCommentEn(clid: $clid, cen: $cen)
	}
`;
const EDIT_CLASS_COMMENT_JP = gql`
	mutation editClassCommentJp($clid: Int, $cjp: String) {
	  editClassCommentJp(clid: $clid, cjp: $cjp)
	}
`;
const EDIT_CLASS_TEACHER = gql`
	mutation editClassTeacher($clid: Int, $teacher: String) {
	  editClassTeacher(clid: $clid, teacher: $teacher)
	}
`;
const EDIT_CLASS_UNIT = gql`
	mutation editClassUnit($clid: Int, $unit: Int) {
	  editClassUnit(clid: $clid, unit: $unit)
	}
`;
const DELETE_CLASS = gql`
	mutation deleteClass($clid: Int) {
	  deleteClass(clid: $clid)
	}
`;
const GRADEALL_SUBCATEGORY = gql`
	mutation gradeAllSubcategory($clid: Int, $scid: Int, $glvl: String) {
	  gradeAllSubcategory(clid: $clid, scid: $scid, glvl: $glvl)
	}
`;

/**
 * Page displaying all details for a single class (one term)
 * Students are represented as rows in a table with columns for inputting grades
 * Functions include:
 * 		-adding/removing students to the class
 * 		-modifying class and student comments
 * 		-class average and comparison to other class averages
 * 		-generate reports in the form of a PDF for double sided printing
 */
class Course extends React.Component {
	constructor(props) {
		super(props);
		this.state = {studentList: [], subcategories: [], classId: props.match.params.id, class_name: "", modalShow: false, currentActiveStid: -1, emptyGrades:false,
				classUnit:"", classCommentEn:"",classCommentJp:"",classTeacher:"",termYear:0,termNumber:0,termId:0, addStudent:false, createStudent:false,studentError:false, avgs: {},
				deleteClassShow:false, deleteInput:"", generateLoading:false, translateLoading:false, loading:true, gAllSC:-1, gAllLvl:"", activeClass:false, isAdmin: false, headerTop:0,
				translations:[],allAverages:[],reportComments:[],tooltips:{},charCount:0, modalFixClass:"", focusRow:-1, focusCat:-1, simComment:"o",simFont:9, simClassComment:"",
				simCommentJp:"", simClassCommentJp:"", avgLoading:true};
		this.avgDict = {};
		this.showAddStudent = this.showAddStudent.bind(this);
		this.hideAddStudent = this.hideAddStudent.bind(this);
		this.showCreateStudent = this.showCreateStudent.bind(this);
		this.appendStudent = this.appendStudent.bind(this);
		this.hideStudentError = this.hideStudentError.bind(this);
		this.removeStudent = this.removeStudent.bind(this);
		this.editAvgDict = this.editAvgDict.bind(this);
		this.calculateAverages = this.calculateAverages.bind(this);
		this.calculateAverageForScid = this.calculateAverageForScid.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);	
		this.handleCommentEnChange = this.handleCommentEnChange.bind(this);  
		this.handleCommentJpChange = this.handleCommentJpChange.bind(this);
		this.handleTeacherChange = this.handleTeacherChange.bind(this);
		this.handleUnitChange = this.handleUnitChange.bind(this);
		this.typingTimerEn = null;
		this.typingTimerJp = null;
		this.typingTimerTeach = null;
		this.typingTimerUnit = null;
		this.activateStid = this.activateStid.bind(this);
		this.generateReports = this.generateReports.bind(this);
		this.refInvalids = React.createRef();
		this.handleDeleteClose = this.handleDeleteClose.bind(this);
		this.handleDeleteShow = this.handleDeleteShow.bind(this);	
		this.deleteTextChange = this.deleteTextChange.bind(this);	
		this.deleteClass = this.deleteClass.bind(this);	
		this.translateToJp = this.translateToJp.bind(this);
		this.gAllSubcat = this.gAllSubcat.bind(this);

		this.handleScroll = this.handleScroll.bind(this);
		this.throttledFunc = throttle(this.handleScroll,100);
		this.refBottom = React.createRef();

		this.getUnit = this.getUnit.bind(this);
		//Max number of characters for comments
		this.maxChars = "700";

		this.focusModal = this.focusModal.bind(this);
		this.blurModal = this.blurModal.bind(this);
		
		this.focusDown = this.focusDown.bind(this);
		this.focusUp = this.focusUp.bind(this);

		this.simRef = React.createRef();
	}
	
	//Load page once data is retrieved
	async componentDidMount() {
		window.addEventListener('scroll', this.throttledFunc);
		this.mounted = true;

		const idTokenPayload = (await Auth.currentSession()).getIdToken().payload;
		let admin = false;
		if (idTokenPayload["cognito:groups"]) {
			if (idTokenPayload["cognito:groups"].includes("fujiadmin")) {
				this.setState({isAdmin:true});
				admin = true;
			}
		}

		while(this.mounted && !this.props.data.hasOwnProperty("getClass"))
			await new Promise(resolve => setTimeout(resolve, 100));
		

		if (this.mounted) {
			//Create dict containing all students' grades (skipping students flagged to exclude) for each subcategory to calculate class average 
			this.props.data.getClass.subcategories.forEach((subcategory) =>  {
				this.avgDict[(subcategory.subcategory_id)] = {};
			});
			this.props.data.getClass.student_list.forEach((student) =>  {
				if (student.exclude_avg == false) {
					student.grades.forEach((grade) => {
						if (this.avgDict[grade.subcategory_id] && grade.grade_level!="X" && grade.grade_level!="") {
							this.avgDict[grade.subcategory_id][student.details.student_id] = grade.grade_level;
						}
					});
				}
			});
			this.calculateAverages();
			let activeClass = this.props.data.getClass.term_active;
			if (!!admin) {
				activeClass = true;
			}
			this.setState({studentList: this.props.data.getClass.student_list,
				subcategories: this.props.data.getClass.subcategories,
				classId: this.props.data.getClass.class_id,
				class_name: this.props.data.getClass.class_name,
				classCommentEn: this.props.data.getClass.class_comment_en,
				classCommentJp: this.props.data.getClass.class_comment_jp,
				classTeacher: this.props.data.getClass.class_teacher,
				termYear: this.props.data.getClass.term_year,
				termNumber: this.props.data.getClass.term_number,
				termId: this.props.data.getClass.term_id,
				activeClass: activeClass,
				charCount: this.props.data.getClass.class_comment_en.length
				});
			let unit = 'undefined';
			if (this.props.data.getClass.class_unit) {
				unit = this.props.data.getClass.class_unit;
			}
			this.setState({classUnit: unit});
			document.title = "Fuji - "+ this.props.data.getClass.class_name;

			this.setState({translations: this.props.data.getTranslations});
			//Create dict for Japanese comments corresponding to each grade that is printed on the report
			let comms = {};
			this.props.data.getReportComments.forEach((subcategory) =>  {
				let grades = {};
				subcategory.grades.forEach((grade) =>  {
					grades[(grade.grade_level)] = grade.grade_jp;
				});
				comms[(subcategory.subcategory_id)] = {grades:grades};
			});
			this.setState({reportComments: comms});
			
			let tooltips = {};
			//Create dict for tooltips shown when hovering over a grade input
			this.props.data.getClass.subcategories.forEach((subcategory) => {
				tooltips[subcategory.subcategory_id] = {"X":""};
				for (let i=0; i<subcategory.subcategory_max; i++) {
					tooltips[subcategory.subcategory_id][i] = "";
				}
			});
			
					
			this.props.data.getTooltips.forEach((tooltip) =>  {
				if (tooltips[tooltip.subcategory_id]) {
					tooltips[tooltip.subcategory_id][tooltip.grade_level] = tooltip.tooltip;
				}
			});
			this.setState({tooltips: tooltips});

			this.setState({loading: false});
			//Get all other class averages after initial load to prevent blocking
			const {data} = await this.props.client.query({
				query: GET_ALL_AVERAGES,
				variables: { clid: parseInt(this.props.data.getClass.class_id)},
				fetchPolicy: "network-only",
			  });
			this.setState({allAverages: data.getAllAverages});
			
			this.setState({avgLoading: false});
		
		}
	}
	
	//If page is redirected before inputs are able to autosubmit, submit them
	async componentWillUnmount() {
		window.removeEventListener('scroll', this.throttledFunc);
		if (this.typingTimerEn) {
			this.onEditClassCommentEnMutate( parseInt(this.state.classId), this.state.classCommentEn );
			clearTimeout(this.typingTimerEn);
		}
		if (this.typingTimerJp) {
			this.onEditClassCommentJpMutate( parseInt(this.state.classId), this.state.classCommentJp )
			clearTimeout(this.typingTimerJp);
		}
		if (this.typingTimerTeach) {
			this.onEditClassTeacherMutate( parseInt(this.state.classId), this.state.classTeacher )
			clearTimeout(this.typingTimerTeach);
		}
		if (this.typingTimerUnit) {
			let unit = this.state.classUnit;
			if (unit == 'undefined' || unit == '') {
				unit = null;
			}
			this.onEditClassUnitMutate( parseInt(this.state.classId), parseInt(unit) )
			clearTimeout(this.typingTimerUnit);
		}
	}

	//Stick table headers to top of page when scrolling them out of vision
	handleScroll(event) {
		let scrollTop = event.target.documentElement.scrollTop;
		let tableTop = this.refInvalids.current.offsetTop;
		let tableBottom = tableTop+this.refBottom.current.offsetTop-150;
		if (scrollTop>tableTop) {
			if (scrollTop<tableBottom) {
				this.setState({headerTop:scrollTop-tableTop});
			}
			
		} else {
			this.setState({headerTop:0});
		}
	}
	
	showAddStudent(){
		this.setState({addStudent: true});
	}
	hideAddStudent(){
		this.setState({addStudent: false});
		this.setState({createStudent: false});
		this.setState({studentError: false});
	}
	showCreateStudent(){
		this.setState({createStudent: true});
	}
	//Add student to this class in the database, and then display them in the table
	async appendStudent(stid){
		const { data } = await this.onJoinClassMutate(parseInt(stid),parseInt(this.state.classId),parseInt(this.state.termId));
		if (data.studentJoinClass) {
			this.setState({studentList: [...this.state.studentList, data.studentJoinClass]});
			this.setState({addStudent: false});
			this.setState({studentError: false});
			if (data.studentJoinClass.exclude_avg == false) {
				data.studentJoinClass.grades.forEach((grade) => {
					if (this.avgDict[grade.subcategory_id] && grade.grade_level!="X" && grade.grade_level!="") {
						this.avgDict[grade.subcategory_id][data.studentJoinClass.student_id] = grade.grade_level;
					}
				});
				this.calculateAverages();
			}
		} else {
			this.setState({studentError: true});
		}
	}
	hideStudentError(){
		this.setState({studentError: false});
	}

	//Remove student from this class in both the database and table
	removeStudent(stid) {
		this.onRemoveStudentMutate( parseInt(stid), parseInt(this.state.classId) );
		const studentList = this.state.studentList;
		const index = studentList.findIndex(x => x.details.student_id == parseInt(stid));
		if (index > -1) {
			studentList.splice(index, 1);
			this.setState({studentList: studentList});
		}
		
		this.state.subcategories.forEach((subcategory) =>  {
			delete this.avgDict[subcategory.subcategory_id][stid];
		});
		
		this.calculateAverages();
	}
	//When modifying a grade, adjust the average dictionary accordingly
	editAvgDict(scid,stid,glvl) {
		if (this.avgDict[scid]) {
			if (glvl != "" && glvl != "X") {
				this.avgDict[scid][stid] = parseInt(glvl);
			} else delete this.avgDict[scid][stid];
		}
		this.calculateAverageForScid(scid);
	}
	//Calculate averages for all subcategories to one decimal place
	calculateAverages(){
		let avgs = {};
		for (const [key, value] of Object.entries(this.avgDict)) {
			let sum = 0;
			let numVals = 0;
			for( const el in value ) {
				if( value.hasOwnProperty( el ) ) {
					numVals++;
					sum += parseFloat( value[el] );
				}
			}
			if (numVals==0) {
				avgs[key] = 0;
			} else {
				avgs[key] = Math.round((sum/numVals) * 10) / 10
			}
			
		}
		this.setState({avgs: avgs});
	}
	//Calculate average for a single subcategory to one decimal place
	calculateAverageForScid(scid){
		let avgs = this.state.avgs;

		let sum = 0;
		let numVals = 0;
		for( const el in this.avgDict[scid] ) {
			if( this.avgDict[scid].hasOwnProperty( el ) ) {
				numVals++;
				sum += parseFloat( this.avgDict[scid][el] );
			}
		}
		if (numVals==0) {
			avgs[scid] = 0;
		} else {
			avgs[scid] = Math.round((sum/numVals) * 10) / 10
		}

		this.setState({avgs: avgs});
	}
	
	
	handleClose() {
		this.setState({modalShow: false});
	}
	handleShow(){
		this.setState({modalShow: true});
	}
	//Autosubmit change in comment one second after typing stops
	handleCommentEnChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({classCommentEn: value, charCount:value.length});
		clearTimeout(this.typingTimerEn);
		this.typingTimerEn = setTimeout(() => {
			this.onEditClassCommentEnMutate( parseInt(this.state.classId), value );
			this.typingTimerEn = null;
		}, 1000);
	}
	
	handleCommentJpChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({classCommentJp: value});
		clearTimeout(this.typingTimerJp);
		this.typingTimerJp = setTimeout(() => {
			this.onEditClassCommentJpMutate( parseInt(this.state.classId), value )
			this.typingTimerJp = null;
		}, 1000);
	}	
	//Activate a student's row to show additional info
	activateStid(stid) {
		this.setState({currentActiveStid: stid});
	}
	
	handleTeacherChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({classTeacher: value});
		clearTimeout(this.typingTimerTeach);
		this.typingTimerTeach = setTimeout(() => {
			this.onEditClassTeacherMutate( parseInt(this.state.classId), value )
			this.typingTimerTeach = null;
		}, 1000);
	}	
	
	handleUnitChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({classUnit: value});
		clearTimeout(this.typingTimerUnit);
		let unit = value;
		if (value == 'undefined' || value == '') {
			unit = null;
		}
		this.typingTimerUnit = setTimeout(() => {
			this.onEditClassUnitMutate( parseInt(this.state.classId), parseInt(unit) )
			this.typingTimerUnit = null;
		}, 1000);
	}	

	handleDeleteClose() {
		this.setState({deleteClassShow: false});
		}
	handleDeleteShow(){
		this.setState({deleteClassShow: true});
	}
	
	deleteTextChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({deleteInput: value});
	}
	
	deleteClass() {
		this.onDeleteClassMutate( parseInt(this.state.classId) );
		this.props.history.push('/active');
	}
	//Sends the English class comment to be translated to Japanese and inputs the returned translation
	async translateToJp() {
		this.setState({translateLoading: true});
				
		let tempComment = this.state.classCommentEn;
		//Modify custom translations to be untranslateable
		this.state.translations.forEach((translation) => {
			
			const regex = new RegExp('\\b' + translation.original + '\\b', "g");
			tempComment = tempComment.replaceAll(regex, "<Q"+translation.original.replace(/ /g,'')+"Q>");	
		});
		
		let jp = await translate(tempComment, "ja");
		
		//Input the custom translation replacements
		this.state.translations.forEach((translation) => {
			jp = jp.replaceAll("<Q"+translation.original.replace(/ /g,'')+"Q>", translation.replacement);
		});
	
		this.setState({classCommentJp: jp});
		this.onEditClassCommentJpMutate( parseInt(this.state.classId), jp )
		this.setState({translateLoading: false});
	}

	//Apply the grade for a subcategory to all students in the class
	gAllSubcat(subcatId, glvl) {
		this.setState({gAllSC: subcatId, gAllLvl: glvl});
	}

	getUnit(unit){
		let newUnit = unit;
		if (unit == 'undefined' || unit == '') {
			newUnit = '-';
		}
		return newUnit;
	}

	focusModal(){
		this.setState({modalFixClass: "modal-abs"});
	}
	
	blurModal(){
		this.setState({modalFixClass: ""});
	}
	//Move input focus down a row, or when at the bottom move to the top of the next column
	focusDown(rowId,catId){
		let nextRow = rowId+1;
		let nextCat = catId;
		if (nextRow>this.state.studentList.length-1) {
			nextRow = 0;
			if (nextCat>this.state.subcategories.length-2) {
				nextCat = 0;
			} else nextCat = nextCat+1;
		}
		this.setState({focusRow: nextRow, focusCat: nextCat});
	}
	//Move input focus up a row, or when at the top move to the bottom of the previous column
	focusUp(rowId,catId){
		let nextRow = rowId-1;
		let nextCat = catId;
		if (nextRow<0) {
			nextRow = this.state.studentList.length-1;
			if (nextCat-1<0) {
				nextCat = this.state.subcategories.length-1;
			} else nextCat = nextCat-1;
		}
		this.setState({focusRow: nextRow, focusCat: nextCat});
	}
	//Generate reports for all the students in the class as a single pdf
	async generateReports(){
		//Check if all fields are filled in
		if (!!this.state.activeClass && (!!this.refInvalids.current.querySelector(".class-invalid-grade") || this.state.classCommentEn == "" || this.state.classCommentJp == "") ) {
			this.setState({emptyGrades:true});
		} else {
			this.setState({generateLoading:true});
			this.setState({emptyGrades:false});
			//fetch reports data
			const {data} = await this.props.client.query({
			  query: CLASS_REPORT,
			  variables: { clid: parseInt(this.state.classId) },
			  fetchPolicy: 'network-only',
			});
			let reportAvgDict = {};
			data.getClassReport.categories.forEach((category) => {
				category.subcategories.forEach((subcategory) =>  {
					reportAvgDict[(subcategory.subcategory_id)] = {};
				});
			});
			data.getClassReport.student_list.forEach((student) =>  {
				if (student.exclude_avg == false) {
					student.grades.forEach((grade) => {
						if (reportAvgDict[grade.subcategory_id] && grade.grade_level!="X" && grade.grade_level!="") {
							reportAvgDict[grade.subcategory_id][student.details.student_id] = grade.grade_level;
						}
					});
				}
			});
			//create dict to lookup average by subcategory id
			let avgs = {};
			for (const [key, value] of Object.entries(reportAvgDict)) {
				let sum = 0;
				let numVals = 0;
				for( const el in value ) {
					if( value.hasOwnProperty( el ) ) {
						numVals++;
						sum += parseFloat( value[el] );
					}
				}
				if (numVals==0) {
					avgs[key] = 0;
				} else {
					avgs[key] = Math.round((sum/numVals) * 10) / 10
				}
				
			}
			
			let pages = [];
			let firstStudent = true;
			this.setState({simClassComment: data.getClassReport.class_comment_en, simClassCommentJp: data.getClassReport.class_comment_jp});

			data.getClassReport.student_list.forEach((student) =>  {

				let classUnit = data.getClassReport.class_unit;
				if (!data.getClassReport.class_unit) {
					classUnit = "-";
				}
				//title image on page 2
				let page2t = {layout: 'imageLayout', margin: [0, 0, 0, 20],pageBreak: 'before',
					table: {
							headerRows: 1,
							widths: [ '100%' ],
				
							body: [
								[ {image: 'success', width:'260', alignment:'center'}]
							]
						}
					};
				//student information table on page 2
				let page2a = {layout: 'detailsLayout',
					table: {
						headerRows: 0,
						widths: [ '23%', '31%', '21%', '25%' ],
						
						body: [
							[ {text:'Class',alignment:'center', style:'nameHeader'}, {text:'English name',alignment:'center', style:'nameHeader'}, {text:'', border: [false, false, false, false]},
								{text:'Term '+data.getClassReport.term_number+', '+data.getClassReport.term_year,alignment:'center', style:'termHeader', border: [true, true, true, false]}],
								
							[ {text:data.getClassReport.class_name,alignment:'center'}, {text:student.details.english_name,alignment:'center'},{text:'', border: [false, false, false, false]},{text:'', border: [true, false, true, true]}],
							
							[ {text:'Teacher',alignment:'center', style:'nameHeader'}, {text:'Japanese name',alignment:'center', style:'nameHeader'},{text:'', border: [false, false, false, false]} , {text:'Unit',alignment:'center'}],
							
							[ {text:data.getClassReport.class_teacher,alignment:'center'}, {text:student.details.japanese_name,alignment:'center'}, {text:'', border: [false, false, false, false]},
								{text:classUnit,alignment:'center'}]
						]
					  }
					
					};
				
				//Simulate comment size in box and shrink font if necessary until it fits
				let commentFontSize = 9;
				this.setState({simComment: student.comment_en, simFont: commentFontSize, simCommentJp: student.comment_jp});
				
				while (this.simRef.current.clientHeight>420) {
					commentFontSize = commentFontSize-0.3;
					this.setState({simFont: commentFontSize});
				}

				//class and student comments table on page 2
				let page2b = {layout: 'commentsLayout', margin: [0, 20, 0, 0],
					table: {
							headerRows: 0,
							heights: ['auto',100,'auto',100],
							widths: [ '50%', '50%' ],
							body: [
								[ {text:'Class Comment', style:'commentHeader',fillColor: '#f0afdf',alignment:'center'}, {text:'クラスについてのコメント (自動翻訳)', style:'commentHeader',fillColor: '#f0afdf',alignment:'center'}],
								[ {text:data.getClassReport.class_comment_en, fontSize:commentFontSize}, {text:data.getClassReport.class_comment_jp, fontSize:commentFontSize}],
								[ {text:'Student Comment', style:'commentHeader',fillColor: '#9fc5e8',alignment:'center'}, {text:'生徒についてのコメント (自動翻訳)', style:'commentHeader',fillColor: '#9fc5e8',alignment:'center'}],
								[ {text:student.comment_en, fontSize:commentFontSize}, {text:student.comment_jp, fontSize:commentFontSize} ],
							]
						}
					}

				//logo on bottom of page 2
				let page2c = {layout: 'imageLayout', margin: [0, 20, 0, 0],
					table: {
							headerRows: 1,
							widths: [ '100%' ],
				
							body: [
								[ {image: 'kobito', width:'150', alignment:'center'}]
							]
						}
					};

				//table of grades on page 1
				let firstCategory = true;
				data.getClassReport.categories.forEach((category) => {
					let categoryTable;
					//include the headings if drawing the first category table
					let headingRow;
					if (!!firstCategory) {
						categoryTable = {layout: 'headingLayout', table: {
						headerRows: 0, 
						widths: [ '25%', '23%', '11%', '11%', '30%' ],
						body: []
						}};
						let startRow = [ {text:'', border: [false, false, false, false]},
							{text:'', border: [false, false, false, false]}, 
							{text:"Average",alignment:'center',style:'avgHeaderEN', border: [true, true, true, false]},
						{text:"Student Ability",style:'categoryHeader',alignment:'center', border: [true, true, true, false], colSpan:2},'']
							
						categoryTable.table.body.push(startRow);
						headingRow = [ {text:category.category_en,style:'categoryHeader',alignment:'center', border: [false, false, false, false]},
							{text:category.category_jp,style:'categoryHeader',alignment:'center', border: [false, false, false, false]}, 
							{text:"クラス平均",alignment:'center',style:'avgHeaderJP', border: [true, false, true, false]},
							{text:"生徒の実力",style:'categoryHeader',alignment:'center', border: [true, false, true, false], colSpan:2},'']
							
						if (!firstStudent) categoryTable.pageBreak = 'before';
						
					} else {
						categoryTable = {layout: 'tableLayout', table: {
							headerRows: 0, 
							widths: [ '25%', '23%', '11%', '11%', '30%' ],
							body: []
							}};
						headingRow = [ {text:category.category_en,style:'categoryHeader',alignment:'center', border: [false, false, false, false]},
							{text:category.category_jp,style:'categoryHeader',alignment:'center', border: [false, false, false, false]}, 
							{text:'', border: [false, false, false, false]},
							{text:'', border: [false, false, false, false], colSpan:2},'']
					}
					
					categoryTable.table.body.push(headingRow);
					//add subcategory rows to the category table				
					let firstSubcategory = true;
					category.subcategories.forEach((subcategory) =>  {
						
						const index = student.grades.findIndex(x => x.subcategory_id == subcategory.subcategory_id);
						if (index > -1) {
							//exclude unassessed subcategories
							if (student.grades[index].grade_level!="X" && student.grades[index].grade_level!="") {
								const gradeIndex = subcategory.grades.findIndex(x => x.grade_level == parseInt(student.grades[index].grade_level));
								
								let row = [ {text: subcategory.subcategory_en, fillColor: category.colour,alignment:'center' , style:'subcategoryHeader' }, 
									{text: subcategory.subcategory_jp, fillColor: category.colour,alignment:'center' , style:'subcategoryHeader' }, 
									{text:avgs[subcategory.subcategory_id]+"/"+subcategory.subcategory_max,alignment:'center', style:'gradeNum'},
									{text:student.grades[index].grade_level+"/"+subcategory.subcategory_max, style:'gradeNum',alignment:'center'},
									{text:subcategory.grades[gradeIndex].grade_jp, style:'grade',alignment:'left'} ]
								
								categoryTable.table.body.push(row);
							}
							
						}
						
					});
					if (categoryTable.table.body.length > 1) {
						pages.push(categoryTable);
						if (!!firstStudent) firstStudent = false;
						if (!!firstCategory) firstCategory = false;
					}
				});
				pages.push(page2t);
				pages.push(page2a);
				pages.push(page2b);
				pages.push(page2c);
				
			});
			
			
			
			let docDefinition = { content: pages,
				defaultStyle: {
					font: 'MPLUS'
				  },
				styles: {
				
				topHeader: {
					fontSize: 15,
				},
				categoryHeader: {
					bold: true,
					fontSize: 12,
					color: 'black'
				},
				avgHeaderEN: {
					bold: true,
					fontSize: 11,
					color: 'black',
				},
				avgHeaderJP: {
					bold: true,
					fontSize: 10,
					color: 'black',
				},
				subcategoryHeader: {
					bold: true,
					fontSize: 9,
					color: 'black'
				},
				gradeNum: {
					fontSize: 10,
				},
				grade: {
					fontSize: 9,
				},
				commentHeader: {
					fontSize: 9,
				},
				termHeader: {
					fontSize: 16,
					bold:true
				},
				nameHeader: {
					bold:true
				},
				comment: {
					fontSize: 9,
				},
				commentS: {
					fontSize: 8.5,
				}
					
				
			},
			images: {
				kobito: homeURL+'/KobitoSmall.png',
				success: homeURL+'/success.png'
			  }
			};

			const today = new Date();
			const dd = String(today.getDate()).padStart(2, '0');
			const mm = String(today.getMonth() + 1).padStart(2, '0');
			const yyyy = today.getFullYear();
			//define filename for pdf
			const docTitle = data.getClassReport.class_name + ' Term ' + data.getClassReport.term_number + ', ' + 
					data.getClassReport.term_year + ' (' + yyyy + '-' + mm + '-' + dd + ').pdf';
			pdfMake.createPdf(docDefinition).download(docTitle);
			this.setState({generateLoading:false});		
		}
	}
	

	
	render() {
		let studentSearch;
		let studentErrorNotice;
		//Display card to add a student to the class
		if (this.state.addStudent) {
			let addContents;
			if (this.state.createStudent==true) {
				addContents = <div></div>;
			} else {
				addContents = <><StudentSearch addStudentFunc={this.appendStudent} hideErrorFunc={this.hideStudentError}/></>;
				//<Button variant="success" onClick={this.showCreateStudent}>New Student</Button></>;
			}
			if (this.state.studentError){
				studentErrorNotice = <p className="student-add-error">Student is already in a class</p>;
			}
			
			studentSearch = <Card className="studentlist-new-card" style={{ width: '18rem' }}>
							<div className="d-flex justify-content-end"><Button variant="secondary" onClick={this.hideAddStudent}>Cancel</Button></div>
								{studentErrorNotice}
								<Mutation mutation={STUDENT_TO_CLASS}>
									{(joinClassMutate) => {
										this.onJoinClassMutate = (stid, clid, tid) => joinClassMutate( {variables: { stid, clid, tid } } )
										return (
											null
										)
									}
								 }
								</Mutation>
								{addContents}
							  
							</Card>;
		} else {
			studentSearch = <Button variant="success" onClick={this.showAddStudent}>Add Student</Button>;
		}
		
		let emptyGradeClass="";
		let emptyError;
		if (this.state.emptyGrades){
			emptyGradeClass = " class-empty-grades";
			emptyError = <p className="wrong-login-notice">Please fill in all scores/comments</p>;
		}
		let emptyClassCommentClass = "";
		if (this.state.classCommentEn == "" || this.state.classCommentJp == "") {
			emptyClassCommentClass = "class-invalid-grade";
		}

		let generateButton = <Button variant="warning" onClick={this.generateReports}>Generate Reports</Button>;
		if (this.state.generateLoading==true) {
			generateButton = <Spinner animation="border" variant="primary" />;
		}
		let translateButton = <Button variant="primary" onClick={this.translateToJp}>Translate</Button>;
		if (this.state.translateLoading == true) {
			translateButton = <Spinner animation="border" variant="primary" />;
		}
		
		let commentTooltip;
		if (this.state.classCommentEn != "" || this.state.classCommentJp !="") {
			commentTooltip = <Tooltip className="class-comment-tooltip">
								{this.state.classCommentEn}
								<div className="underline"></div>
								{this.state.classCommentJp}
						</Tooltip>
		}
		

		let classBlock = <Row className="d-flex justify-content-center"><Spinner animation="border" variant="primary" /></Row>;
		if (!this.state.loading) {
			//Hidden boxes to simulate size of report comments for font scaling, class details, table of students grades
			classBlock = <><Row>
						<div style={{position:"absolute",top:0,left:0,zIndex:-1,visibility:"hidden",overflow:"hidden",width:"1px",pointerEvents:"none"}} ref={this.simRef}> 
							<div className="d-flex">
								<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simClassComment}</div>
								<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simClassCommentJp}</div>
							</div>
							<div className="d-flex">
								<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simComment}</div>
								<div style={{width:"337px",minWidth:"337px",minHeight:"120px",fontSize:this.state.simFont+"pt",zIndex:-2,whiteSpace:"pre-wrap",padding:"2px 8px",wordBreak:"break-word",fontFamily:"pdfEng"}}>{this.state.simCommentJp}</div>
							</div>
						</div>
				<Mutation mutation={EDIT_CLASS_COMMENT_EN}>
					{(editClassCommentEnMutate) => {
						this.onEditClassCommentEnMutate = (clid,cen) => editClassCommentEnMutate( {variables: { clid, cen } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Mutation mutation={EDIT_CLASS_COMMENT_JP}>
					{(editClassCommentJpMutate) => {
						this.onEditClassCommentJpMutate = (clid,cjp) => editClassCommentJpMutate( {variables: { clid, cjp } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Modal show={this.state.modalShow} onHide={this.handleClose} animation={false}>
					<Modal.Header closeButton>
					<Modal.Title>Comments for {this.state.class_name}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{!!this.state.activeClass && <><Form.Label className="class-student-comment-label">English<span>{this.state.charCount}/{this.maxChars}</span></Form.Label>
							<TextareaAutosize value={this.state.classCommentEn} onChange={this.handleCommentEnChange} className="class-student-comment-modal" maxLength={this.maxChars}
								onFocus={this.focusModal} onBlur={this.blurModal} />
							<div className="d-flex justify-content-center">{translateButton}</div>
							<Form.Label>Japanese</Form.Label>
							<TextareaAutosize value={this.state.classCommentJp} onChange={this.handleCommentJpChange} className="class-student-comment-modal"
								onFocus={this.focusModal} onBlur={this.blurModal} /></>}
						{!this.state.activeClass && <><p>{this.state.classCommentEn}</p><p>{this.state.classCommentJp}</p></>}
					</Modal.Body>
				</Modal>
				
				<Mutation mutation={EDIT_CLASS_TEACHER}>
					{(editClassTeacherMutate) => {
						this.onEditClassTeacherMutate = (clid,teacher) => editClassTeacherMutate( {variables: { clid, teacher } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Mutation mutation={EDIT_CLASS_UNIT}>
					{(editClassUnitMutate) => {
						this.onEditClassUnitMutate = (clid,unit) => editClassUnitMutate( {variables: { clid, unit } } )
						return (
							null
						)
					}
				 }
				</Mutation>
			<Col className="class-details">
				<h1 className="text-center">{this.state.class_name}</h1>
				<h3 className="text-center">Term {this.state.termNumber}, {this.state.termYear}</h3>
				<h4>
					{!!this.state.activeClass && <input type="text" name="classTeacher" placeholder="Teacher Name" value={this.state.classTeacher} onChange={this.handleTeacherChange}/>}
					{!this.state.activeClass && this.state.classTeacher}
				</h4>
				<h5>Unit: {!!this.state.activeClass && <input type="number" min="0" step="1" name="classUnit" placeholder="#" value={this.state.classUnit} onChange={this.handleUnitChange}/>}
					{!this.state.activeClass && this.getUnit(this.state.classUnit)}	
				</h5>
				<div className={"class-details-button"+emptyGradeClass}>
					<Button className={emptyClassCommentClass} variant="primary" onClick={this.handleShow}>Class Comment
						{commentTooltip}
					</Button>
				</div>
				<div className="class-details-button">{generateButton}</div>
				{emptyError}
				<div className="class-add-student">
					{this.state.activeClass && studentSearch}
				</div>
			</Col>
			</Row>
			<Row>
			<Table ref={this.refInvalids} hover className={"table-header-rotated"+emptyGradeClass} style={{marginTop:"195px"}} responsive>
			  <thead>
				<tr>
				  <th className="course-names">English Name</th>
				  <th className="course-names">Japanese Name</th>
				  {this.state.subcategories.map((subcat) =>
						<th className="rotate" key={"CH"+subcat.subcategory_id} style={{top:this.state.headerTop+"px"}}>
							<div><span style={{backgroundColor:subcat.colour}}>{subcat.subcategory_en}</span></div>
						</th>
					)}

				  <th className="class-student-comment">Comment</th>
				</tr>
			  </thead>
			  <tbody>
			  <Mutation mutation={REMOVE_STUDENT}>
					{(removeStudentMutate) => {
						this.onRemoveStudentMutate = (stid,clid) => removeStudentMutate( {variables: { stid, clid } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				{this.state.studentList.map((student, index) =>
					<CourseRow key={"S"+student.details.student_id} studentId={student.details.student_id} grades={student.grades} prevGrades={student.prev_grades}
						prevPrevGrades={student.prev_prev_grades} subcategories={this.state.subcategories}
						englishName={student.details.english_name} japaneseName={student.details.japanese_name} termId={this.state.termId}
						commentEn={student.comment_en} commentJp={student.comment_jp} prevCommentEn={student.prev_comment_en} prevCommentJp={student.prev_comment_jp}
						removeStudentFunc={this.removeStudent} history={this.props.history} editAvgFunc={this.editAvgDict} currentActiveStid={this.state.currentActiveStid}
						activeFunc={this.activateStid} gAllSC={this.state.gAllSC} gAllLvl={this.state.gAllLvl} activeClass={this.state.activeClass} excludeAvg={student.exclude_avg}
						translations={this.state.translations} reportComments={this.state.reportComments} tooltips={this.state.tooltips}
						focusRowId={index} focusRow={this.state.focusRow} focusCat={this.state.focusCat} focusDown={this.focusDown} focusUp={this.focusUp}/>
					)}

				<CourseAverages subcategories={this.state.subcategories} avgs={this.state.avgs} refBottom={this.refBottom}/>
				{this.state.activeClass && <XAll subcategories={this.state.subcategories} xSubcatFunc={this.gAllSubcat} classId={this.state.classId} tooltips={this.state.tooltips}/>}
				
				{!this.state.avgLoading && <AllAverages subcategories={this.state.subcategories} allAverages={this.state.allAverages} classUnit={this.state.classUnit}/>}
			  </tbody>
			</Table>
			</Row>
			<Row className="class-delete-row">
				<Mutation mutation={DELETE_CLASS}>
					{(deleteClassMutate) => {
						this.onDeleteClassMutate = (clid) => deleteClassMutate( {variables: { clid } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<Modal show={this.state.deleteClassShow} onHide={this.handleDeleteClose} animation={false} centered>
					<Modal.Header closeButton>
						<Modal.Title>Confirm Delete</Modal.Title>
					</Modal.Header>
					<Modal.Body>Are you sure you want to permanently delete {this.state.class_name}?
					<p>Type <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>delete</span> to confirm</p></Modal.Body>
					
						<Form.Control
							className="class-delete-input"
						  aria-label="delete class"
						  onChange={this.deleteTextChange}
						  value={this.state.deleteInput}
						/>
					<Modal.Footer>
					<Button variant="secondary" onClick={this.handleDeleteClose}>Cancel</Button>
					<Button variant="danger" onClick={this.deleteClass} disabled={this.state.deleteInput!='delete'}>Delete</Button>
					</Modal.Footer>
				</Modal>
				{!!this.state.isAdmin && <Col className="d-flex justify-content-end">
					<Button variant="danger" onClick={this.handleDeleteShow}>Delete Class</Button>
				</Col>}
			
			</Row></>;

		}
		//Ensure font is downloaded before generating reports
		return (
			<div className="course">
				<Container fluid>
					<div style={{position:"absolute",zIndex:-3,visibility:"hidden",fontFamily:"pdfEng",width:"1px",pointerEvents:"none"}}>o</div>
					{classBlock}
				</Container>
			</div>
		);
	}
}

/**
 * Table row with fields for the class average of each subcategory
 */
class CourseAverages extends React.Component {
	constructor(props) {
		super(props);
		
	}
	
	render() {
		return (
			<tr className="class-student-row" ref={this.props.refBottom}>
				<td className="course-names table-borderless"></td>
				<td className="course-names">Average</td>
				{this.props.subcategories.map((subcat) =>
				<td key={"AVG"+subcat.subcategory_id} className="class-average"><span className="class-avg-over">{this.props.avgs[subcat.subcategory_id]}</span>
						<span className="class-avg-div"></span>
					<span className="class-avg-under">{subcat.subcategory_max}</span></td>
					)}
			</tr>
		);
	}
}

/**
 * Table row with buttons to set the grade of a subcategory for all students in the class
 */
class XAll extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {modalShow:false, subcatId:-1, subcatEn:"", glvl:"", options:null};
		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);
		this.markX = this.markX.bind(this);
		
		this.handleGradeChange = this.handleGradeChange.bind(this);
	}
	//Display the modal to select the grade that is to be set
	handleShow(subcatId,subcatEn,subcatMax){
		let options = [];
		
		for (let i = 1; i < subcatMax+1; i++) {
			options.push(<option key={"XI"+i} value={i}>{i}</option>);
		}
		this.setState({subcatId:subcatId,subcatEn:subcatEn, modalShow:true, options:options, glvl:""});
	}
	handleHide(){
		this.setState({modalShow:false});
	}
	//Set the corresponding fields in the table and submit the change to the database
	markX(){
		this.props.xSubcatFunc(this.state.subcatId, this.state.glvl);
		this.onGradeAllSubcatMutate(parseInt(this.props.classId), parseInt(this.state.subcatId), this.state.glvl);
		this.setState({modalShow:false});
	}
	
	handleGradeChange(event){
		const target = event.target;
		const value = target.value;
		this.setState({glvl: value});
	}
	
	render() {
		return (
			<tr className="class-student-row">
				<Modal show={this.state.modalShow} onHide={this.handleHide} animation={false} centered>
					<Modal.Header>
						<Modal.Title>{this.state.subcatEn}</Modal.Title>
					</Modal.Header>
					<Modal.Body>Set the scores in <span style={{fontWeight:"bold"}}>{this.state.subcatEn}</span> for all students to:
					<Form.Control as="select"
					  value={this.state.glvl}
					  onChange={this.handleGradeChange}
					  >
						<option value=""></option>
						
						{this.state.options}
						<option value="X">X</option>
					</Form.Control>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={this.handleHide}>Cancel</Button>
					<Button variant="success" onClick={this.markX} >Confirm</Button>
					</Modal.Footer>
				</Modal>
				<Mutation mutation={GRADEALL_SUBCATEGORY}>
					{(gradeAllSubcatMutate) => {
						this.onGradeAllSubcatMutate = (clid, scid, glvl) => gradeAllSubcatMutate( {variables: { clid, scid, glvl } } )
						return (
							null
						)
					}
				 }
				</Mutation>
				<td className="course-names table-borderless"></td>
				<td className="course-names table-borderless"></td>
				{this.props.subcategories.map((subcat) =>
				<td key={"X"+subcat.subcategory_id} className="class-x-all table-borderless"><div className="d-flex justify-content-center align-items-center">
					<Button onClick={this.handleShow.bind(this,subcat.subcategory_id,subcat.subcategory_en,subcat.subcategory_max)}  className="class-xall-button">∀
					{(this.props.tooltips[subcat.subcategory_id]["X"] != "") && <Tooltip className="class-xall-tooltip">
									{this.props.tooltips[subcat.subcategory_id]["X"]}
					</Tooltip>}
					</Button>
				</div></td>
					)}
			</tr>
		);
	}
}

/**
 * Table row that shows other class averages in the current term
 */
class AllAverages extends React.Component {
	constructor(props) {
		super(props);
		this.allAverages = this.props.allAverages;
		//Set the initial select option to the class with the closest unit level
		let initialOption = 0;
		let initialUnitDiff = 100;
		if (this.allAverages[initialOption]) {
			initialUnitDiff = Math.abs(this.allAverages[initialOption].class_unit - this.props.classUnit);
		}
		let options = [];
		for (let i = 0; i < this.allAverages.length; i++) {
			let unitText = "";
			if (this.allAverages[i].class_unit) {
				unitText = "U"+this.allAverages[i].class_unit+" ";
			}
			options.push(<option key={"CA"+i} value={i}>{unitText}{this.allAverages[i].class_name}</option>);
			let unitDiff = Math.abs(this.allAverages[i].class_unit - this.props.classUnit);
			if (unitDiff < initialUnitDiff) {
				initialOption = i;
				initialUnitDiff = unitDiff;
			}
		}
		let avgs = {};
		if (this.allAverages[initialOption]) {
			this.allAverages[initialOption].averages.forEach(average => {
				avgs[average.subcategory_id] = average.grade_level;
				});	
		}
		this.props.subcategories.forEach(subcat => {
				if (!avgs[subcat.subcategory_id]) {
					avgs[subcat.subcategory_id] = 0;
				}
			});
		let selectedTeacher = "";
		if (this.allAverages[initialOption]) {
			if (this.allAverages[initialOption].class_teacher) {
				selectedTeacher = this.allAverages[initialOption].class_teacher;
			}
		}
		this.state = {options:options, selectedAvg:initialOption, averages:avgs, selectedTeacher:selectedTeacher}
		this.handleAvgChange = this.handleAvgChange.bind(this);
	}
	
	handleAvgChange(event) {
		const target = event.target;
		const value = target.value;
		let avgs = {};
		if (this.allAverages[value]) {
			this.allAverages[value].averages.forEach(average => {
					avgs[average.subcategory_id] = average.grade_level;
				});	
		}
		this.props.subcategories.forEach(subcat => {
				if (!avgs[subcat.subcategory_id]) {
					avgs[subcat.subcategory_id] = 0;
				}
			});
		let selectedTeacher = "";
		if (this.allAverages[value]) {
			if (this.allAverages[value].class_teacher) {
				selectedTeacher = this.allAverages[value].class_teacher;
			}
		}	
			
		this.setState({selectedAvg: value, averages:avgs, selectedTeacher:selectedTeacher});
	}
	
	render() {
		return (
			<tr className="class-student-row">
				
				<td className="course-names course-all-avg-select"><Form.Control as="select"
				  value={this.state.selectedAvg}
				  onChange={this.handleAvgChange}
				  >
					{this.state.options}
				</Form.Control></td>
				<td className="course-names">{this.state.selectedTeacher}</td>
				{this.props.subcategories.map((subcat) =>
				<td key={"AVG"+subcat.subcategory_id} className="class-average"><span className="class-avg-over">{this.state.averages[subcat.subcategory_id]}</span>
						<span className="class-avg-div"></span>
					<span className="class-avg-under">{subcat.subcategory_max}</span></td>
					)}
			</tr>
		);
	}
}

export default withApollo(graphql(GET_CLASS, {
  options: props => ({
	  variables: {
		  clid: parseInt(props.match.params.id)
		},
	  fetchPolicy: 'network-only',
	 }),
})(Course));