import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { gql } from 'apollo-boost';
import { Mutation, Query } from '@apollo/client/react/components';
import ReportGrade from './reportgradeadmin';



const NEW_GRADE = gql`
	mutation newReportGrade($cid: Int, $scid: Int, $glvl: Int) {
		newReportGrade(cid: $cid, scid: $scid, glvl: $glvl){
			 grade_id
			grade_level
			grade_en
			grade_jp
		}	
		
	}
`;


const EDIT_SUBCATEGORY_EN = gql`
	mutation editReportSubcategoryEn($scid: Int, $text: String) {
	  editReportSubcategoryEn(scid: $scid, text: $text)
	}
`;

const EDIT_SUBCATEGORY_JP = gql`
	mutation editReportSubcategoryJp($scid: Int, $text: String) {
	  editReportSubcategoryJp(scid: $scid, text: $text)
	}
`;

const EDIT_SUBCATEGORY_UNIT = gql`
	mutation editReportSubcategoryUnit($scid: Int, $text: String) {
	  editReportSubcategoryUnit(scid: $scid, text: $text)
	}
`;


const DEL_GRADE = gql`
	mutation deleteReportGrade($gid: Int) {
	  deleteReportGrade(gid: $gid)
	}
`;



class ReportSubcategory extends React.Component {
	constructor(props) {
		super(props);
		let subMax = this.props.subcategoryMaxGrade;
		if (subMax<2) {
			subMax = 2;
		}
		this.state = {grades: this.props.grades,
						subcategoryEn: this.props.subcategoryEn,
						subcategoryJp: this.props.subcategoryJp,
						subcategoryUnit: this.props.subcategoryUnit,
						subcategoryMax: subMax,
						modalShow: false};
		this.categoryId = this.props.categoryId;
		this.subcategoryId = this.props.subcategoryId;
		this.newGrade = this.newGrade.bind(this);  
		this.removeGrade = this.removeGrade.bind(this);  
		this.handleSubcatEnChange = this.handleSubcatEnChange.bind(this);  
		this.handleSubcatJpChange = this.handleSubcatJpChange.bind(this);  
		this.handleSubcatUnitChange = this.handleSubcatUnitChange.bind(this);  
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.getMax = this.getMax.bind(this);
		this.typingTimerEn = null;
		this.typingTimerJp = null;
		this.typingTimerUnit = null;
		this.props.maxGradeFunc(this.subcategoryId,this.state.subcategoryMax);
	}
	
	async componentWillUnmount() {
		if (this.typingTimerEn) {
			this.onEditSubcatEnMutate( parseInt(this.subcategoryId), this.state.subcategoryEn );
			clearTimeout(this.typingTimerEn);
		}
		if (this.typingTimerJp) {
			this.onEditSubcatJpMutate( parseInt(this.subcategoryId), this.state.subcategoryJp );
			clearTimeout(this.typingTimerJp);
		}
		if (this.typingTimerUnit) {
			this.onEditSubcatUnitMutate( parseInt(this.subcategoryId), this.state.subcategoryUnit );
			clearTimeout(this.typingTimerUnit);
		}
	}
	
	newGrade(data) {
		if (data.newReportGrade) {
			this.setState({grades: [...this.state.grades, data.newReportGrade]});
			this.setState({subcategoryMax: data.newReportGrade.grade_level});
			this.props.maxGradeFunc(this.subcategoryId,data.newReportGrade.grade_level);
		}
	}
	
	removeGrade(grade_id) {
		this.onDeleteGMutate( parseInt(grade_id) );
		let gradeList = this.state.grades;
		gradeList.pop();
		this.setState({subcategoryMax: gradeList.length});
		this.props.maxGradeFunc(this.subcategoryId,gradeList.length);
		this.setState({grades: gradeList});

	}

	handleSubcatEnChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({subcategoryEn: value});
		clearTimeout(this.typingTimerEn);
		this.typingTimerEn = setTimeout(() => {
			this.onEditSubcatEnMutate( parseInt(this.subcategoryId), value );
			this.typingTimerEn = null;
		}, 1000);
	}
	handleSubcatJpChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({subcategoryJp: value});
		clearTimeout(this.typingTimerJp);
		this.typingTimerJp = setTimeout(() => {
			this.onEditSubcatJpMutate( parseInt(this.subcategoryId), value );
			this.typingTimerJp = null;
		}, 1000);
	}
	
	handleSubcatUnitChange(event) {
		const target = event.target;
		const value = target.value;
		this.setState({subcategoryUnit: value});
		clearTimeout(this.typingTimerUnit);
		this.typingTimerUnit = setTimeout(() => {
			this.onEditSubcatUnitMutate( parseInt(this.subcategoryId), value );
			this.typingTimerUnit = null;
		}, 1000);
	}
	
	handleClose() {
		this.setState({modalShow: false});
	}
	handleShow(){
		this.setState({modalShow: true});
	}
	

	getMax(){
		return this.state.grades.length;
	}
	

	render() {
		const langEn = ((this.props.lang!="en") ? "inactive-lang" : "");
		const langJp = ((this.props.lang!="jp") ? "inactive-lang" : "");
		
		let addButton;
		if (this.state.grades.length<10) {
			addButton = <td className="report-add-grade">
					<div>
						<Mutation mutation={NEW_GRADE} onCompleted={this.newGrade} >
							{(onMutate) => {
								const onMutateFunc = () => {
									onMutate( {variables: { cid: parseInt(this.categoryId),scid: parseInt(this.subcategoryId), glvl: this.state.grades.length+1 } } );								
								};
								return (
									<Button variant="success" onClick={onMutateFunc}>Add Grade</Button>
								)
							 }
							}
						</Mutation>
						
					</div>
				</td>;
		} else {
			addButton = null;
		}
		
		return (
		
			<tr>
				<td className="report-subcategory-heading">
					<div>
					<Mutation mutation={EDIT_SUBCATEGORY_EN}>
						{(editSubcatEnMutate) => {
							this.onEditSubcatEnMutate = (scid, text) => editSubcatEnMutate( {variables: { scid, text } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<Mutation mutation={EDIT_SUBCATEGORY_JP}>
						{(editSubcatJpMutate) => {
							this.onEditSubcatJpMutate = (scid, text) => editSubcatJpMutate( {variables: { scid, text } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<input className={langEn} value={this.state.subcategoryEn} onChange={this.handleSubcatEnChange} placeholder="Subcategory"/>
					<span>{this.state.subcategoryEn}</span>
					<input className={langJp} value={this.state.subcategoryJp} onChange={this.handleSubcatJpChange} placeholder="Subcategory (JP)"/>
					<span>{this.state.subcategoryJp}</span>
					<Button className="report-delete-subcategory" variant="danger" onClick={this.handleShow}>
						Delete Subcategory
					</Button>
					<Modal show={this.state.modalShow} onHide={this.handleClose} animation={false} centered>
						<Modal.Header>
						<Modal.Title>Confirm delete</Modal.Title>
						</Modal.Header>
						<Modal.Body>Are you sure you want to delete {(this.state.subcategoryEn) ? this.state.subcategoryEn : " this"}?</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleClose}>
								Cancel
							</Button>
							<Button variant="danger" onClick={this.props.removeSCFunc.bind(this,this.subcategoryId)}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>
					</div>
				</td>
				
				<td className="report-subcategory-level">
					<Mutation mutation={EDIT_SUBCATEGORY_UNIT}>
						{(editSubcatUnitMutate) => {
							this.onEditSubcatUnitMutate = (scid, text) => editSubcatUnitMutate( {variables: { scid, text } } )
							return (
								null
							)
						}
					 }
					</Mutation>
					<input value={this.state.subcategoryUnit} onChange={this.handleSubcatUnitChange} placeholder="U"/>
				
				</td>
				
				<Mutation mutation={DEL_GRADE}>
						{(deleteGMutate) => {
							this.onDeleteGMutate = (gid) => deleteGMutate( {variables: { gid } } )
							return (
								null
							)
						}
					 }
					</Mutation>
				{this.state.grades.map((grade,ind) =>
					<ReportGrade key={"G"+grade.grade_id} gradeId={grade.grade_id} gradeEn={grade.grade_en} gradeJp={grade.grade_jp}  lang={this.props.lang}
						gradeLvl={grade.grade_level} removeGFunc={this.removeGrade} maxFunc={this.getMax}/>
				)}
				
				{addButton}
			</tr>

			
		);		
		
		
	}
}





export default ReportSubcategory;
