/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apolloapi",
            "endpoint": "https://jv6yv7qpxb.execute-api.ap-northeast-1.amazonaws.com/backend",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:39eecd98-e1ca-4ad4-90b9-72b414e51166",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_NrczlN32O",
    "aws_user_pools_web_client_id": "7oa9u4r6n152efbfg7j6bji0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fujibucket30234-backend",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
